import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAssemblyByID, getConfigByID, resetSvgSelectedLayers, updateSvgSelectedLayers, deleteSvgSelectedLayer, updateSvgPartsMetaData,updateSvgParts } from '../../axios/index'

import "./index.css";

function Header(props) { 
  const dispatch = useDispatch();
  const svgSource = useSelector((state) => state.svgViewerData)
  const svgViewerPartData = useSelector((state) => state.svgViewerPartData)
  const parts = useSelector((state) => state.svgViewerPartData.data.configData.partData)
  const updateConfigMetaData = (key, val) =>{
      dispatch(updateSvgPartsMetaData({key:key, val:val}))
  }

  console.log("Header Props: ", props);
  const handleEditMode = (e) => {
    console.log("clicked")
    const checkPartSelected = parts.filter((part, index)=> part.data.partStatus == true)   
    if(checkPartSelected.length > 0) {
      props.setEditMode(!props.editMode);
    }else{
      alert("Please select part for edit.")
    }
   
  }

const renderHeaderBar= () =>{

  if(1>0){
    return(
      <>
          <div>
            <div>
              <span>Assembly  Id</span>
            </div>  		
            <div>
              <b>{svgSource?.data?.assemblyData?.metaData?.assemblyID}</b>
            </div>
          </div>
          <div>
            <div>
              <span>Container</span>
            </div>
            <div>
              <b>{svgSource?.data?.assemblyData?.metaData?.containerMetaData?.containerName}</b>   					
            </div>
          </div>
          <div>
            <div>
              <span>Edit Mode</span>
            </div>
            <div>
              <button
              disabled={
               !((svgSource.data.configData.metaData.configUnits == "inch" && props.selectedScale == 1)
                || (svgSource.data.configData.metaData.configUnits == "mm" && props.selectedScale == 2)
                || (svgSource.data.configData.metaData.configUnits == "cm" && props.selectedScale == 3)
              )
              }
              // style={{
              //   backgroundColor: props.editMode ? "grey" : "#000000",
              //   color: props.editMode ? "#000000" : "grey",
              // }}
              onClick={handleEditMode}
              >{props.editMode ? "OFF" : "ON"}</button>			
            </div>
          </div>
        
          {props.moduleName.trim() === "Configuration" && !props.preview ?
            <>
              <div>
                <div>
                  <span>Confiuration Name</span>
                </div>
                <div>
                  <input type='text' value={svgViewerPartData?.data?.configData?.metaData?.configName} className='form-control edit-input' style={{ width: "230px", height: "20px", fontSize: "12px"}} onChange={(e)=>{updateConfigMetaData('configName',e.target.value )}} />
                </div>
              </div>
              <div>
                <div>
                  <span>Description</span>
                </div>
                <div>
                  <input type='text' value={svgViewerPartData?.data?.configData?.metaData?.configDescription} className='form-control edit-input' style={{width: "230px", height: "20px", fontSize: "12px"}} onChange={(e)=>{updateConfigMetaData('configDescription',e.target.value )}} />
                </div>
              </div>                    
            </>
            :
            <div>
              <div>
                <small><span>CAD Scale</span></small>
              </div>
              <div>
                <b>
                {svgSource?.data?.assemblyData?.metaData?.containerMetaData?.cadScale == 1 ? "Inch" : ""}
                {svgSource?.data?.assemblyData?.metaData?.containerMetaData?.cadScale == 2 ? "Millimeter" : ""}
                {svgSource?.data?.assemblyData?.metaData?.containerMetaData?.cadScale == 3 ? "Centimeter" : ""}
                </b>
              </div>
            </div>
          }
        </> 
    )
  }  
}

  return (
    <div className="header-title-txt">
        {renderHeaderBar()}
    </div>
  );
};

export default Header;
