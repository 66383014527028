import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SvgComponent from "../../icons/svgComponent";
import brand from "./brand.jpg";
import "./index.css";
import logo from "./logo.png";
// import { message } from "antd";
import { withPermission } from "../../hoc";

function Header(props) {
  const dispatch = useDispatch();
  // const [messageApi, contextHolder] = message.useMessage();
  const { oktaAuth, authState } = useOktaAuth();
  const userInfo = useSelector((state) => state.userInfo);
  // const success = () => {
  //   messageApi.open({
  //     type: "success",
  //     content: "This is a success message",
  //     duration: 10,
  //   });
  //   messageApi.success("Copied Successfully");
  // };
  // const error = () => {
  //   messageApi.open({
  //     type: "error",
  //     content: "This is an error message",
  //   });
  // };
  // const warning = () => {
  //   messageApi.open({
  //     type: "warning",
  //     content: "This is a warning message",
  //   });
  // };

  function viewDashboardTab() {
    return (
      <li className="nav-item">
        <Link className="nav-link" to="/admin">
          <SvgComponent
            type={"config"}
            width={"20px"}
            customClass={"headerIcon"}
            height={"20px"}
            background={"transprent"}
          />
          <p>
            <span className=" d-md-block">Admin</span>
          </p>
        </Link>
      </li>
    );
  }
  // Render dashboard view if permission is allowed
  const RenderDashboard = withPermission(viewDashboardTab, ["NL_ADMIN"]);

  const handleCopyToken = () => {
    let localState = JSON.parse(localStorage.getItem("okta-token-storage"));
    let token = "";
    if (localState && localState.hasOwnProperty("idToken")) {
      let idToken = localState.idToken.idToken;
      token = `Bearer ${idToken}`;
    }

    navigator.clipboard.writeText(token).then(
      () => {
        console.log("token copied");
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        console.error("Failed to copy");

        /* Rejected - text failed to copy to the clipboard */
      }
    );
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-transparent  bg-primary  navbar-absolute fixed-top">
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button type="button" className="navbar-toggler">
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <Link className="navbar-brand" to="/">
            {" "}
            <img src={logo} /> <img src={brand} style={{ paddingLeft: "20px", maxWidth: "94px" }} />{" "}
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navigation">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                <SvgComponent
                  type={"home"}
                  width={"20px"}
                  customClass={"headerIcon"}
                  height={"20px"}
                  background={"transprent"}
                />
                <p>
                  <span className=" d-md-block">Home</span>
                </p>
              </Link>
            </li>
            <RenderDashboard />
            <li className="nav-item">
              <Link className="nav-link" to="/geometry" exact>
                <SvgComponent
                  type={"geometry"}
                  width={"22px"}
                  customClass={"headerIcon"}
                  height={"22px"}
                  background={"transprent"}
                />

                <p>
                  <span className=" d-md-block">Geometry</span>
                </p>
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <SvgComponent
                  type={"material-db"}
                  width={"20px"}
                  customClass={"headerIcon"}
                  height={"20px"}
                  background={"transprent"}
                />
                <p>
                  <span className="d-md-block">MATERIAL-Home</span>
                </p>
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <Link className="nav-link" to="/material-database" exact>
                  <SvgComponent
                    type={"material-db"}
                    width={"22px"}
                    customClass={"headerIcon"}
                    height={"22px"}
                    background={"transprent"}
                  />

                  <p>
                    <span className=" d-md-block">Material-Home</span>
                  </p>
                </Link>
                <Link className="nav-link" to="/materials" exact>
                  <SvgComponent
                    type={"material"}
                    width={"22px"}
                    customClass={"headerIcon"}
                    height={"22px"}
                    background={"transprent"}
                  />

                  <p>
                    <span className=" d-md-block">Materials</span>
                  </p>
                </Link>
                <Link className="nav-link" to="/fibers">
                  <SvgComponent
                    type={"fiber"}
                    width={"30px"}
                    customClass={"headerIcon"}
                    height={"30px"}
                    background={"transprent"}
                  />
                  <p>
                    <span className="d-md-block">Fibers</span>
                  </p>
                </Link>
                <Link className="nav-link" to="/sam">
                  <SvgComponent
                    type={"sam"}
                    width={"30px"}
                    customClass={"headerIcon"}
                    height={"30px"}
                    background={"transprent"}
                  />
                  <p>
                    <span className=" d-md-block">SAM</span>
                  </p>
                </Link>
                <Link className="nav-link" to="/mechanical-properties">
                  <SvgComponent
                    type={"geometry"}
                    width={"30px"}
                    customClass={"headerIcon"}
                    height={"30px"}
                    background={"transprent"}
                  />
                  <p>
                    <span className=" d-md-block">Mechanical Properties</span>
                  </p>
                </Link>
              </div>
            </li>

            {/* <li className="nav-item">
              <Link className="nav-link" to="/materials" exact>
                <SvgComponent
                  type={"material"}
                  width={"22px"}
                  customClass={"headerIcon"}
                  height={"22px"}
                  background={"transprent"}
                />

                <p>
                  <span className=" d-md-block">Materials</span>
                </p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/fibers">
                <SvgComponent
                  type={"fiber"}
                  width={"30px"}
                  customClass={"headerIcon"}
                  height={"30px"}
                  background={"transprent"}
                />
                <p>
                  <span className="d-md-block">Fibers</span>
                </p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/sam">
                <SvgComponent
                  type={"sam"}
                  width={"30px"}
                  customClass={"headerIcon"}
                  height={"30px"}
                  background={"transprent"}
                />
                <p>
                  <span className=" d-md-block">SAM</span>
                </p>
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <SvgComponent
                  type={"profile"}
                  width={"20px"}
                  customClass={"headerIcon"}
                  height={"20px"}
                  background={"transprent"}
                />
                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a className="dropdown-item" style={{ borderBottom: "1px solid #06509e" }}>
                  {userInfo.given_name !== undefined ? userInfo.given_name : "..."}{" "}
                  {userInfo.family_name !== undefined ? userInfo.family_name : "loading"}
                </a>
                <Link className="dropdown-item" to="/tool-overview">
                  Tool Overview
                </Link>
                <div className="dropdown-item" onClick={(e) => handleCopyToken()}>
                  Copy Token
                </div>
                <a className="dropdown-item" href="#" onClick={(e) => oktaAuth.signOut()}>
                  Log Out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Header;
