import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SvgViewer from "./svgViewer";
import HeaderInputs from "./Header";
import HeaderActionButtons from "./ActionButtons";
import "./index.css";


import DefaultBreadCrumb from "../breadcrumb"
import eyeClosed from './eye-closed.svg';
import eyeOpen from './eye-open.svg';
import Loader from './loader';
import Layers from "./Layers";
import cloneDeep from 'lodash/cloneDeep';
import PartList from "./PartsList";
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_NONE, fitSelection, zoomOnViewerCenter, fitToViewer } from 'react-svg-pan-zoom';
import { getAssemblyByID, getConfigByID, resetSvgSelectedLayers, updateSvgSelectedLayers, updateSvgPartByKey, updateSvgPartByMulitpleKeys, deleteSvgSelectedLayer, updateSvgParts, resetLayerVisibility, updateLayerVisibility, updateAllSvgParts } from '../../axios/index'

function LoadContainer(props) {
   const { configId, moduleName } = useParams();
   const dispatch = useDispatch();
   const [selectedFile, setSelectedFile] = useState({})
   const [hiddenLayers, setHiddenlayers] = useState({})
   const [sourcePathData, setSourcePathData] = useState([])
   const [selectedlayers, setSelectedlayers] = useState({})
   const [parts, setParts] = useState([])
   const [originPoints, setOriginPoints] = useState([])
   const [error, setError] = useState({ status: false, msg: '', loading: true })
   const [pstatus, setPstatus] = useState(false)
   const [pathPointsState, setPathPointsState] = useState({})
   const [groupedPaths, setGroupedPaths] = useState([])
   const [targetLayerPopup, setTargetLayerPopup] = useState(false)
   const [showPoints, setShowPoints] = useState({ status: false, index: '', type: 'Select' })
   const svgSource = useSelector((state) => state.svgViewerData)
   const svgViewerPartData = useSelector((state) => state.svgViewerPartData)
   const selectedlayerData = useSelector((state) => state.svgSelectedLayers)
   const svgLayerVisibility = useSelector((state) => state.svgLayerVisibility)
   const [selectedPart, setSelectedPart] = useState(null);

   const checkEquality = (val1, val2) => {
      let epsilon = 0.0001;
      let difference = Math.abs(val1 - val2);
      if (difference < epsilon) {
         return true;
      }
      else {
         return false;
      }
   }
   const getAreaCoordinates = (points, type) => {
      let tempCoordinates = { xCoordinates: [], yCoordinates: [] }
      points.map((item, index) => {

         item.map((datapointArray, arrayIndex) => {
            if (item.length > 0 && index === 1) {
               datapointArray.map((point, pointIndex) => {
                  tempCoordinates.xCoordinates.push(point[0])
                  tempCoordinates.yCoordinates.push(point[1])
               })
            }
            else if (item.length > 0 && index === 0) {
               if (type === 'area' || datapointArray[0].l) {
                  datapointArray.map((lastNode, datapointArray) => {
                     tempCoordinates.xCoordinates.push(lastNode[0])
                     tempCoordinates.yCoordinates.push(lastNode[1])
                  })
               }
               else {

                  tempCoordinates.xCoordinates.push(datapointArray[0])
                  tempCoordinates.yCoordinates.push(datapointArray[1])
               }
            }
         })




      })
      return tempCoordinates;
   }

   //get direction 
   const getAngle = (direction) => {
      if (direction === 'positiveX') {
         //  return((90*Math.PI)/180)
         return (90)
      }
      else if (direction === 'negativeX') {
         return (-90)
         // return((-270*Math.PI)/180)
      }
      else if (direction === 'positiveY') {
         return (0)
         // return((0*Math.PI)/180)
      }
      else if (direction === 'negativeY') {
         return (180)
         // return((180*Math.PI)/180)
      }

   }
   //tranformSopurce into new Coodinate System & generate paths 
   const transformSourceData = (sourcePoints, origin, direction) => {
      let ranges = {
         x: {
             "min": 0,
             "max": 0
         },
         y: {
             "min": 0,
             "max": 0
         }
     }
      if (direction === undefined || sourcePoints.length === 0) {
         alert("Target part has no data points or direction is not defined")
         //return false;
      }
      else {
         //All Calulations --- 

         let angle = getAngle(direction);
         //targetAngle - The orientation of the configuration (this is constant)

         let configAngle = ((90 * Math.PI) / 180)

         //Part axis in DXF/SVG coordinate system

         let x = Math.cos(Number(angle));
         let y = Math.sin(angle)
         let newX = x / Math.sqrt(x * x + y * y);
         let newY = y / Math.sqrt(x * x + y * y);

         //Configuration Axis
         let configX = Math.cos(configAngle);
         let configY = Math.sin(configAngle);

         let axis = (newX * configX) + (newY * configY)

         let ACOS = Math.acos(axis);
         let rotangle2 = angle * Math.PI / 180
         let partTransformedCoordinates = [[], []];
         let partTransformedSvgPath = "";
         sourcePoints.map((sourcePoint, sourceIndex) => {
            let tempSet = []
            let newPath = sourceIndex === 0 ? 'M' : '';
            sourcePoint.map((coordinates, groupIndex) => {

               if (sourceIndex === 0) {                 
                  let tempX = (((Number(coordinates[0])) - Number(origin.x)) * Math.cos(rotangle2)) - ((Number(coordinates[1]) - Number(origin.y)) * Math.sin(rotangle2) + 0)
                  let tempY = (((Number(coordinates[0])) - Number(origin.x)) * Math.sin(rotangle2)) + ((Number(coordinates[1]) - Number(origin.y)) * Math.cos(rotangle2) + 0)
                  tempSet.push([tempX, tempY])
                  newPath = newPath + ' ' + tempX + ', ' + tempY;
                 
                  if(groupIndex===0){
                     ranges.x.min = tempX;
                     ranges.x.max = tempX
                     ranges.y.min = tempY;
                     ranges.y.max = tempY;
                  }
                  else{
                     ranges = getNewRanges(tempX,tempY, ranges)
                  }

               }
               else {
                  let tempSet2 = []
                  let newPath2 = 'M';
                  coordinates.map((pointCoordinates, pointCoordinatesIndex) => {
                     let tempX = (((Number(pointCoordinates[0])) - Number(origin.x)) * Math.cos(rotangle2)) - ((Number(pointCoordinates[1]) - Number(origin.y)) * Math.sin(rotangle2))
                     let tempY = (((Number(pointCoordinates[0])) - Number(origin.x)) * Math.sin(rotangle2)) + ((Number(pointCoordinates[1]) - Number(origin.y)) * Math.cos(rotangle2))
                     tempSet2.push([tempX, tempY])
                     newPath2 = newPath2 + ' ' + tempX + ', ' + tempY;
                     ranges = getNewRanges(tempX,tempY, ranges)
                      

                  })
                  newPath = newPath + newPath2 + 'Z'
                  tempSet.push(tempSet2)


               }



            })
            if ((newPath.trim() !== '') && (newPath.trim() !== 'M')) {
               partTransformedSvgPath = partTransformedSvgPath + newPath;
               partTransformedCoordinates[sourceIndex].push(tempSet);
            }

         })
         return { partTransformedCoordinates: partTransformedCoordinates, partTransformedSvgPath: partTransformedSvgPath, ranges:ranges };
      }
   }


   const getNewRanges = (tempX,tempY, ranges) =>{
      if (tempX < ranges.x.min) {
         ranges.x.min = tempX;
       }
       if(tempX > ranges.x.max){
         ranges.x.max = tempX;
       }
       if (tempY < ranges.y.min) {
         ranges.y.min = tempY;
       }
       if(tempY > ranges.y.max){
         ranges.y.max = tempY;
       }
       return ranges;
   }
   //re calulate hole coordinates and area 
   const reCalulateHoleTransformations = (holePoints, index, origin, direction) => {
      let holeArea = 0;
      let tempHoleRefrence = []

      holePoints.map((holeItem, holeIndex) => {

         tempHoleRefrence.push(holeItem);
         let holeTransformedData = transformSourceData(holeItem.partSourceCoordinates, origin, direction);
         tempHoleRefrence[holeIndex].partTransformedCoordinates = holeTransformedData.partTransformedCoordinates
         tempHoleRefrence[holeIndex].partTransformedSvgPath = holeTransformedData.partTransformedSvgPath
         let tempHoleArea = calulateArea({ type: 'area', target: index, sourcePoints: holeTransformedData.partTransformedCoordinates });
         holeArea = holeArea + tempHoleArea
         tempHoleRefrence[holeIndex].partArea = tempHoleArea
      })
      return ({ holeRefrence: tempHoleRefrence, area: holeArea })
   }

   const reCalulateTransformations = (index, val, type) => {
      //handel the recalulations ---
      let sourcePoints = svgViewerPartData.data.configData.partData[index].data.partSourceCoordinates
      let holePoints = cloneDeep(svgViewerPartData.data.configData.partData[index].data.partHoleReference)

      let origin = { x: 0, y: 0 }
      let direction;
      if (type === "partDirection") {
         origin = svgViewerPartData.data.configData.partData[index].data.partCalculatedOrigin
         direction = val;

      }
      else if (type === 'partCalculatedOrigin') {
         origin = val;
         direction = svgViewerPartData.data.configData.partData[index].data.partDirection;
      }

      let holeArea = 0;
      let tempHoleRefrence = []
      if (holePoints.length > 0) {
         let tempHole = reCalulateHoleTransformations(holePoints, index, origin, direction)
         tempHoleRefrence = tempHole.holeRefrence;
         holeArea = tempHole.area
      }

      let transformedData = transformSourceData(sourcePoints, origin, direction);
      let area = calulateArea({ type: 'area', target: null, sourcePoints: transformedData.partTransformedCoordinates });

      let newdataSet = {
         partTransformedCoordinates: transformedData.partTransformedCoordinates,
         partTransformedSvgPath: transformedData.partTransformedSvgPath,
         partArea: area + holeArea,
         partHoleReference: tempHoleRefrence

      }
      if (type === "partDirection") {
         newdataSet.partDirection = val;
      }
      else if (type === 'partCalculatedOrigin') {
         newdataSet.partCalculatedOrigin = val;
      }
      let tempRanges = {}
      if(svgViewerPartData?.data?.configData?.metaData?.meshType!==undefined && svgViewerPartData.data.configData.metaData.meshType!==''){
         tempRanges = cloneDeep(JSON.parse(svgViewerPartData.data.configData.metaData.meshType))
      }
      if(Object.keys(tempRanges).length>0){
         tempRanges =  getNewRanges(transformedData.ranges.x.min,transformedData.ranges.y.min, tempRanges);
         tempRanges =  getNewRanges(transformedData.ranges.x.max,transformedData.ranges.y.max, tempRanges);
      }  
      else{
         tempRanges = transformedData.ranges;
      }

      dispatch(updateSvgPartByMulitpleKeys({ index: index, data: newdataSet, ranges: tempRanges}))
   }
   //calulate area of the path
   const calulateArea = (src) => {
      let coordinates = src.sourcePoints;
      const xCoordinates = [];
      const yCoordinates = [];
      let data = { xCoordinates: [], yCoordinates: [] }
      let dataWithoutHole = { xCoordinates: [], yCoordinates: [] }
      let tempPoints, tempHoles;

      tempPoints = src.sourcePoints;
      data = getAreaCoordinates(tempPoints, src.type);


      let area = 0;
      const n = data.xCoordinates.length;
      for (let i = 0; i < n; i++) {
         const j = (i + 1) % n;
         area += data.xCoordinates[i] * data.yCoordinates[j];
         area -= data.xCoordinates[j] * data.yCoordinates[i];
      }
      return area;
   }
   const loopThoughPices = (group, layerIndex, target, piece, checkConnectivity) => {
      let sourcePoints = [];
      let tempCoordinates = 'M';
      let currentChild = cloneDeep(piece);
      if (target !== null) {
         currentChild = currentChild.reverse()
      }
      currentChild.map((coordinates, pointIndex) => {
         sourcePoints.push(coordinates)
         tempCoordinates = tempCoordinates + ' ' + coordinates[0] + ', ' + coordinates[1]


      })

      return ({ sourcePoints: sourcePoints, tempCoordinates: tempCoordinates })


   }

   const loopThoughParts = (group, layerIndex, layer, target, previousSourcePoints, piece) => {
      let sourcePoints = []
      let tempCoordinates = '';
      let isConnected = true;
      //currentNode Details

      let currentChild = cloneDeep(piece);
      let isConnectionExists = true;
      //next layer node details
      let nextLayer = []
      let nextNode = []
      let nextChild = []
      // previous Layer node details 
      let previousLayer = []
      let previousNode = []
      let previousChild = []
      if (layerIndex < (group.length - 1)) {
         nextLayer = group[layerIndex + 1];
         nextNode = svgSource.data.assemblyData.data.assembly[nextLayer.parentIndex]
         nextChild = svgSource.data.assemblyData.data.assembly[nextLayer.parentIndex]['xy'][layer.childIndex];
      }

      if (layerIndex > 0 && previousSourcePoints.length > 0) {
         let previousPoint = previousSourcePoints[previousSourcePoints.length - 1];

         let eqCheck = (checkEquality(previousPoint[0], currentChild[0][0]) && checkEquality(previousPoint[1], currentChild[0][1]));
         if (eqCheck === false) {
            let eqCheckLastItem = (checkEquality(previousPoint[0], currentChild[currentChild.length - 1][0]) && checkEquality(previousPoint[1], currentChild[currentChild.length - 1][1]));
            if (eqCheckLastItem === true) {
               isConnectionExists = true;
               currentChild = currentChild.reverse();
            }
            else {
               isConnectionExists = false;
            }
         }
      }
      else if (layerIndex === 0 && nextChild.length > 0) {

         let eqCheck = (checkEquality(nextChild[0][0], currentChild[0][0]) && checkEquality(nextChild[0][1], currentChild[0][1]))

         if (eqCheck === false) {
            let eqCheckLastItem = (checkEquality(nextChild[nextChild.length - 1][0], currentChild[0][0]) && checkEquality(nextChild[nextChild.length - 1][1], currentChild[0][1]))

            if (eqCheckLastItem === false) {
               let eqCurrentChildLastItem = (checkEquality(nextChild[0][0], currentChild[currentChild.length - 1][0]) && checkEquality(nextChild[0][1], currentChild[currentChild.length - 1][1]))

               if (eqCurrentChildLastItem === false) {
                  let eqTargetLastItem = (checkEquality(nextChild[nextChild.length - 1][0], currentChild[currentChild.length - 1][0]) && checkEquality(nextChild[nextChild.length - 1][1], currentChild[currentChild.length - 1][1]))

                  if (eqTargetLastItem === false) {
                     isConnectionExists = false;
                  }
               }
            }
            else {
               isConnectionExists = true;
               currentChild = currentChild.reverse();
            }
         }
         else {
            isConnectionExists = true;
            currentChild = currentChild.reverse();
         }

      }


      if (isConnectionExists === false) {
         alert("no common points")
      }
      else {
         let tempZ = svgSource.data.assemblyData.data.assembly[group[layerIndex].parentIndex]['z'];
         currentChild.map((coordinates, pointIndex) => {
            if (isConnectionExists === true && layerIndex > 0 && pointIndex === 0) {

            }
            else {
               sourcePoints.push(coordinates)
               tempCoordinates = tempCoordinates + ' ' + coordinates[0] + ', ' + coordinates[1]
            }


         })
         return ({ isConnected: isConnected, sourcePoints: sourcePoints, tempCoordinates: tempCoordinates, partZ: tempZ })
      }


   }



   const getGroups = (partData, target) => {
      if (partData.length > 0) {
         let tempPaths = [];
         let tempRanges = {}
      if(svgViewerPartData?.data?.configData?.metaData?.meshType!==undefined && svgViewerPartData.data.configData.metaData.meshType!==''){
         tempRanges = cloneDeep(JSON.parse(svgViewerPartData.data.configData.metaData.meshType))
      }
         partData.map((group, groupIndex) => {
            let tempCoordinates = '';
            let sourcePoints = [[], []]
            let tempZ = []
            let isConnected = true;

            if (group.length > 0) {
               group.map((layer, layerIndex) => {

                  // check and get  connect points as per the parts 
                  if (Object.keys(svgSource.data.assemblyData).length > 0 && isConnected === true) {

                     if (layer.childIndex === -1 && group.length > 1) {
                        alert("You are trying to combine multiple pices which do not have any common points")
                        isConnected = false;
                     }

                     else if (layer.childIndex !== -1 && group.length > 0) {
                        let tempData = loopThoughParts(group, layerIndex, layer, target, sourcePoints[0], svgSource.data.assemblyData.data.assembly[layer.parentIndex]['xy'][layer.childIndex])
                        if (tempData.isConnected === true) {
                           tempCoordinates = tempCoordinates.trim() === '' ? "M" + tempData.tempCoordinates : tempCoordinates + tempData.tempCoordinates
                           sourcePoints[0].push(...tempData.sourcePoints)
                           tempZ.push(...tempData.partZ)

                        }
                        else {
                           isConnected = tempData.isConnected;
                        }
                     }
                     else if (layer.childIndex === -1 && group.length === 1) {
                        // checkConnectivity =  loopThoughParts(group,layerIndex,layer, target, svgSource.data.assemblyData.data.assembly[layer.parentIndex]['xy'][layer.childIndex])
                        svgSource.data.assemblyData.data.assembly[layer.parentIndex]['xy'].map((piece, pieceIndex) => {
                           let tempData = loopThoughPices(group, layerIndex, target, piece, "checkConnectivity")
                           tempCoordinates = tempCoordinates.trim() === '' ? tempCoordinates + tempData.tempCoordinates : tempCoordinates + 'z' + tempData.tempCoordinates
                           sourcePoints[1].push(tempData.sourcePoints);
                           tempZ.push(...svgSource.data.assemblyData.data.assembly[layer.parentIndex]['z'])
                        })

                     }
                  }
               })
            }
            if (isConnected === true) {
               tempPaths.push({
                  'path': tempCoordinates,
                  'part': groupIndex,
                  'z': groupIndex,
                  'color': 'red',
                  'areas': [],

               })
               let partZ = calulateZ(tempZ)
               let tempObj = {
                  action: {
                     updated: false,
                     type: "new",
                     delete: false
                  },
                  data: {
                     configId: '',
                     part: "part1",
                     partType: "",
                     partName: "",
                     partZ: partZ,
                     partDirection: "positiveX",
                     partArea: "",
                     partDescription: "",
                     partCalculatedOrigin: { x: 0, y: 0 },
                     partOriginMetaData: [],
                     partSourceSvgPath: tempCoordinates,
                     partTransformedSvgPath: "",
                     partReference: partData[0],
                     partHoleReference: [],
                     partSourceCoordinates: sourcePoints,
                     partTransformedCoordinates: [],
                     partStatus: false,
                     partVisibility: true
                  }

               }


               let tempObj2 = { rerence: partData[0], svgAssembly: tempPaths, selected: false, visible: true, holes: [] }
               let area = 0.0;

               if (target !== null) {
                  area = calulateArea({ type: 'hole', target: target, sourcePoints: sourcePoints });
                  if (area < 0) {
                     if (sourcePoints[0].length > 0) {
                        sourcePoints[0].reverse()
                     }
                     else if (sourcePoints[1].length > 0) {
                        sourcePoints[1].reverse()
                     }
                     area = calulateArea({ type: 'hole', target: target, sourcePoints: sourcePoints });
                     tempObj.data.partSourceCoordinates = sourcePoints;

                  }

                  let originForHole = svgViewerPartData.data.configData.partData[target].data.partCalculatedOrigin;
                  let partDirection = svgViewerPartData.data.configData.partData[target].data.partDirection;
                  let transformedData = transformSourceData(sourcePoints, originForHole, partDirection);
                  tempObj.data.partTransformedCoordinates = transformedData.partTransformedCoordinates;
                  tempObj.data.partTransformedSvgPath = transformedData.partTransformedSvgPath;
                  tempObj.data.partArea = area;
                  tempObj.data.partZ = partZ;
                  tempObj.ranges = transformedData.ranges
                  if(Object.keys(tempRanges).length>0){
                     tempRanges =  getNewRanges(transformedData.ranges.x.min,transformedData.ranges.y.min, tempRanges);
                     tempRanges =  getNewRanges(transformedData.ranges.x.max,transformedData.ranges.y.max, tempRanges);
                  }  
                  else{
                     tempRanges = transformedData.ranges;
                  }
                  dispatch(updateSvgParts({ type: 'hole', target: Number(target), data: tempObj, ranges: tempRanges}))

                  setTargetLayerPopup(false)
               }
               else {
                  area = calulateArea({ type: 'part', target: target, sourcePoints: sourcePoints });

                  if (area > 0) {

                     if (sourcePoints[0].length > 0) {
                        sourcePoints[0].reverse()
                     }
                     else if (sourcePoints[1].length > 0) {
                        sourcePoints[1].reverse()
                     }
                     area = calulateArea({ type: 'part', target: target, sourcePoints: sourcePoints });
                     tempObj.data.partSourceCoordinates = sourcePoints;
                  }
                  let transformedData = transformSourceData(sourcePoints, { x: 0, y: 0 }, "positiveX")
                  tempObj.data.partTransformedCoordinates = transformedData.partTransformedCoordinates;
                  tempObj.data.partTransformedSvgPath = transformedData.partTransformedSvgPath;


                  tempObj.data.partArea = area;
                  tempObj.data.partZ = partZ;
                  tempObj.ranges = transformedData.ranges
                  if(Object.keys(tempRanges).length>0){
                     tempRanges =  getNewRanges(transformedData.ranges.x.min,transformedData.ranges.y.min, tempRanges);
                     tempRanges =  getNewRanges(transformedData.ranges.x.max,transformedData.ranges.y.max, tempRanges);
                  }  
                  else{
                     tempRanges = transformedData.ranges;
                  }
                  dispatch(updateSvgParts({ type: 'partData', data: tempObj, ranges:tempRanges}))

               }
               dispatch(resetSvgSelectedLayers({}))
               setPstatus(!pstatus)
            }

         })




      }
   }

   const calulateZ = (data) => {
      let totalSum = 0;
      data.map((point, index) => {
         totalSum += Number(point);
      })
      return (totalSum / data.length)
   }


   const getCoordinates = () => {
      if (((moduleName === 'config-viewer' && props.preview === true) || (moduleName === 'assembly-viewer' && props.preview === false)) && Object.keys(svgSource.data.assemblyData).length > 0) {
         let tempPaths = [];
         let layerOptions = []
         console.log(" svgSource.data.assemblyData.data: ",  svgSource.data.assemblyData.data)
         svgSource.data.assemblyData.data.assembly.map((g, index) => {

            //get XY
            let tempPathD = [];
            let tempPoints = []
            let tempPathClosed = []
            layerOptions.push(g.part)

            g.areas.map((area, areaIndex) => {
               if (g.xy[areaIndex].length > 0) {
                  let tempCoordinates = 'M';
                  let tempPoint = []
                  tempPathClosed.push(checkEquality(g.xy[areaIndex][0][0], g.xy[areaIndex][g.xy[areaIndex].length - 1][0]) && checkEquality(g.xy[areaIndex][0][1], g.xy[areaIndex][g.xy[areaIndex].length - 1][1]))

                  g.xy[areaIndex].map((pointCoordinates, pointIndex) => {

                     tempCoordinates = tempCoordinates + ' ' + pointCoordinates[0] + ', ' + pointCoordinates[1]
                     tempPoint.push(pointCoordinates)
                  })
                  //tempPathD.push(tempCoordinates);  
                  if (area > 0 && areaIndex !== 0) {
                     let creatingHole = tempPathD[tempPathD.length - 1]
                     if (creatingHole !== undefined) {
                        creatingHole = creatingHole + 'z' + tempCoordinates + 'z';
                        tempPathD[tempPathD.length - 1] = creatingHole;
                     }

                  }
                  else {
                     tempPathD.push(tempCoordinates);
                  }
                  tempPoints.push(tempPoint)
               }

            })
            tempPaths.push({
               'paths': tempPathD,
               'points': tempPoints,
               'part': g.part,
               'z': g.z,
               'color': g.color,
               'areas': g.areas,
               'pathClosed': tempPathClosed
            })

         })

         console.log("tempPaths: ", tempPaths)
         let actulaLayers = tempPaths.map((item, index) => {

            let isOpaque = item.areas[0] < 0 ? true : false;
            let alpha = isOpaque ? (40 + index) : 0
            let color = `rgba(${item.color[0]},${item.color[1]},${item.color[2]}, 100%)`
            let color1 = `rgba(${item.color[0]},${item.color[1]},${item.color[2]}, ${alpha}%)`
            if (svgLayerVisibility.data.others?.[item.part] === undefined) {
               return (<g display={toggleGroupVisbility(item.part)} fill={color1} stroke={color} id={item.part} className={renderSvgGroupClass(item.part, index)} cursor="default" pointerEvents="all">
                  {renderPaths(item, index)}
                  {renderPoints(item, index)}
               </g>)
            }

         })
         let groupedLayers = tempPaths.map((item, index) => {
            let isOpaque = item.areas[0] < 0 ? true : false;
            let alpha = isOpaque ? (40 + index) : 0
            let color = `rgba(${item.color[0]},${item.color[1]},${item.color[2]}, 100%)`
            let color1 = `rgba(${item.color[0]},${item.color[1]},${item.color[2]}, ${alpha}%)`
            if (svgLayerVisibility.data.others[item.part] === undefined) {
               return (<g display={toggleGroupVisbility(item.part)} fill={color1} stroke={color} id={item.part} className={renderSvgGroupClass(item.part, index)} cursor="default" pointerEvents="all">
                  {renderPaths(item, index)}

               </g>)
            }


         })
         return actulaLayers;
      }
      else {
         return (<></>)
      }
   }
   const updateCoordinate = (e, point) => {
  

      let tempRanges = {}
      if(svgViewerPartData?.data?.configData?.metaData?.meshType!==undefined && svgViewerPartData.data.configData.metaData.meshType!==''){
         tempRanges = cloneDeep(JSON.parse(svgViewerPartData.data.configData.metaData.meshType))
      }

      /* Old Code Start --- */
      let eventType = e.target.getAttribute('data-type');
      let currentChild = cloneDeep(svgViewerPartData?.data?.configData?.partData[showPoints.index]?.data);
      if (eventType === 'point' && currentChild !== undefined) {
         let partOriginMetaData = cloneDeep(svgViewerPartData.data.configData.partData[showPoints.index].data.partOriginMetaData);
         let origin = { x: point[0], y: point[1] }
         let partNewOriginMetaData = []
         let o = false;
         let oindex = 0;
         if (partOriginMetaData.length > 0) {
            partOriginMetaData.map((tempOrigin, oIndex) => {
               if (tempOrigin.x === point[0] && tempOrigin.y === point[1]) {
                  o = true;
                  oindex = oIndex
               }
            })
            if (o === true && partOriginMetaData.length === 1) {
               origin = { x: 0, y: 0 }
               // partNewOriginMetaData.push({x:0, y:0})

            }
            else if (o === true && partOriginMetaData.length === 2) {
               origin = (oindex === 0 ? partOriginMetaData[1] : partOriginMetaData[0])
               partNewOriginMetaData.push(origin)
            }
            else if (o === false && partOriginMetaData.length === 1) {
               let originX = (partOriginMetaData[0]['x'] + point[0]) / 2;
               let originY = (partOriginMetaData[0]['y'] + point[1]) / 2;
               partNewOriginMetaData.push(partOriginMetaData[0])
               partNewOriginMetaData.push({ x: point[0], y: point[1] })
               origin = { x: originX, y: originY }
            }


         }
         else {
            partNewOriginMetaData.push({ x: point[0], y: point[1] })
         }

         if (o === false && (partOriginMetaData.length === 2)) {
            alert('You can select maximum two points to calculate Origin');

         }
         else {

            e.target.classList.toggle('selected')



            let sourcePoints = svgViewerPartData.data.configData.partData[showPoints.index].data.partSourceCoordinates

            let direction = svgViewerPartData.data.configData.partData[showPoints.index].data.partDirection;

            let holePoints = cloneDeep(svgViewerPartData.data.configData.partData[showPoints.index].data.partHoleReference);

            let transformedData = transformSourceData(sourcePoints, origin, direction);
            let area = calulateArea({ type: 'area', target: null, sourcePoints: transformedData.partTransformedCoordinates });

            //Hole Calulations 
            let holeArea = 0;
            let tempHoleRefrence = []
            if (holePoints.length > 0) {
               let tempHole = reCalulateHoleTransformations(holePoints, showPoints.index, origin, direction)
               tempHoleRefrence = tempHole.holeRefrence;
               holeArea = tempHole.area
            }

            if(Object.keys(tempRanges).length>0){
               tempRanges =  getNewRanges(transformedData.ranges.x.min,transformedData.ranges.y.min, tempRanges);
               tempRanges =  getNewRanges(transformedData.ranges.x.max,transformedData.ranges.y.max, tempRanges);
            }  
            else{
               tempRanges = transformedData.ranges;
            }

            let newdataSet = {
               partTransformedCoordinates: transformedData.partTransformedCoordinates,
               partTransformedSvgPath: transformedData.partTransformedSvgPath,
               partCalculatedOrigin: origin,
               partOriginMetaData: partNewOriginMetaData,
               partArea: area + holeArea,
               partHoleReference: tempHoleRefrence
            }
            dispatch(updateSvgPartByMulitpleKeys({ index: showPoints.index, data: newdataSet, ranges:tempRanges }))

         }
         if (showPoints.type === 'set') {
            setShowPoints({ status: false, index: '', type: 'Select' })
         }

      }



   }
   const renderPoints = (item, index) => {
      //console.log("renderPoints item, index: ", item, index)
      if (showPoints.status === true) {
         return item.points.map((tempPoint, tempIndex) => {
            return tempPoint.map((point, pointIndex) => {
               return (<circle data-type='point' className='originPoint' onClick={(e) => updateCoordinate(e, point)} cx={point[0]} cy={point[1]} stroke="yellow" fill="yellow" stroke-width="1" />)
            })
         })
      }
   }
   const renderPaths = (data, parentIndex) => {

      let color = `rgba(${data.color[0]},${data.color[1]},${data.color[2]}, 100%)`
      let isOpaque = data.areas < 0 ? true : false;
      return data.paths.map((path, index) => {
         let color = 'inherit';
         if (data.pathClosed[index] === false) {
            color = 'none'
         }
         let alpha = isOpaque ? (8 + index) : 0
         let color1 = `rgba(${data.color[0]},${data.color[1]},${data.color[2]}, ${alpha}%)`
         //  return(<path onClick={(e)=>handlePathClick(e)} cursor="pointer" stroke={color} fill={color1} strokeWidth="1" d={path} />)
         return (<path fill={color} data-pathType='default' data-parentIndex={parentIndex} data-childIndex={index} id={`${parentIndex}_${index}`} vectorEffect="non-scaling-stroke" onClick={(e) => handlePathClick(e)} cursor="pointer" strokeWidth="2" d={path} />)
      })

   }

   const handlePathClick = (e) => {

   }





   const aa = { let: '0' }
   const toggleGroupVisbility = (part) => {
      if (hiddenLayers[part] !== undefined && hiddenLayers[part] === true) {
         return ("none")
      }
      else {
         return ("inline")
      }
   }
   const renderSvgGroupClass = (part, index) => {
      if (selectedlayerData.data[part] !== undefined && selectedlayerData.data[part]['status'] === true) {
         //if(index===)
         return ("svgPathGroup svgClickablePath selected")
      }
      else {
         return ("svgPathGroup svgClickablePath")
      }
   }
   const toggleLayerVisibility = (key) => {
      if (Object.keys(svgSource.data.assemblyData).length > 0 && svgLayerVisibility.data.all === true) {
         let allParts = {}
         svgSource.data.assemblyData.data.assembly.map((item, index) => {
            allParts[item.part] = false;
         })
         dispatch(updateLayerVisibility({ type: 'all', data: allParts }))

      }
      else {
         dispatch(updateLayerVisibility({ type: 'all' }))
      }

   }

   const toggleVisibilityIcon = (assembly) => {
      if (hiddenLayers[assembly.part] !== undefined) {
         return hiddenLayers[assembly.part] ? eyeClosed : eyeOpen
      }
      else {
         return eyeOpen;
      }
   }


   const renderPartsList = () => {
      if (svgViewerPartData.data.configData.partData.length > 0) {
         return svgViewerPartData.data.configData.partData.map((part, index) => {
            return (
               <option value={index}>Part - {index}</option>
            )
         })
      }
   }


   const handlelayerSelections = (path, source, keyboardKey) => {
      let part, parentIndex, childIndex, pathType;
      console.log("***path: ", path, path.parentElement, path.parentElement.id)
      if (source === 'svgViewer') {

         part = path.parentElement.id;
         parentIndex = path.getAttribute('data-parentIndex')

         pathType = path.getAttribute('data-pathType')
         if (svgSource.data.assemblyData.data.assembly[parentIndex]['xy'].length > 1) {
            childIndex = -1
         }
         else {
            childIndex = path.getAttribute('data-childIndex')
         }
      }
      else {
         part = path.g.part;
         parentIndex = path.index;
         childIndex = -1;
         pathType = path.g.part
      }

      if (keyboardKey === 'altKey' && pathType !== 'part') {
         dispatch(updateLayerVisibility({ type: 'single', key: part }))
      }
      else {

         if (pathType !== 'part' && selectedlayerData.data[part] !== undefined) {
            if (selectedlayerData.data[part]['status'] === true) {
               dispatch(deleteSvgSelectedLayer({ key: part, keyboardKey: keyboardKey }))
            }

         }
         else if (pathType !== 'part' && selectedlayerData.data[part] === undefined) {
            dispatch(updateSvgSelectedLayers({ key: part, data: { status: true, visible: true, parentIndex: parentIndex, childIndex: childIndex }, keyboardKey: keyboardKey }))

         }
         else if (pathType === 'part') {
            let tempStatus = svgViewerPartData?.data?.configData?.partData?.[parentIndex]?.data?.partStatus;
            if (tempStatus !== undefined) {
               let tempKey = 'partStatus';
               if (keyboardKey === 'altKey') {
                  tempKey = 'partVisibility';
                  tempStatus = false;
               }
               else {
                  tempStatus = !tempStatus;
               }
               dispatch(updateSvgPartByKey({ index: parentIndex, key: tempKey, data: tempStatus }))
            }


         }
      }

   }

   const createPart = (actionType, target) => {

      if (actionType === 'hole') {
         setTargetLayerPopup(!targetLayerPopup)
      }


      else if (Object.keys(selectedlayerData.data).length > 0) {
         let tempParts = []
         Object.keys(selectedlayerData.data).map((item, index) => {
            if (selectedlayerData.data[item]['status'] === true && (svgLayerVisibility.data.all === true && svgLayerVisibility.data.others[item] === undefined)) {
               tempParts.push(selectedlayerData.data[item])
            }
         })
         console.log("tempParts: ", tempParts)
         console.log("selectedlayerData.data: ", selectedlayerData.data)
         console.log("selectedlayerData: ", selectedlayerData)
         if (tempParts.length > 0) {
            getGroups([tempParts], target)
         }

      }
      // setPstatus(!pstatus)
   }

   const deletePart = (actionType, target) => {

      if (Object.keys(selectedlayerData.data).length > 0) {
         let tempParts = []
         Object.keys(selectedlayerData.data).map((item, index) => {
            if (selectedlayerData.data[item]['status'] === true && (svgLayerVisibility.data.all === true && svgLayerVisibility.data.others[item] === undefined)) {

              //dispatch(updateSvgSelectedLayers({ key: item, data: { ...selectedlayerData.data, visible: false}, keyboardKey: "altKey"}))
               //tempParts.push(selectedlayerData.data[item])
               dispatch(updateLayerVisibility({ type: 'single', key: item }))
            }
         })
        // console.log("tempParts: ", tempParts)
         // if (tempParts.length > 0) {
         //    getGroups([tempParts], target)
         // }

      }
   }



   const handelTargetLayerSelectionFOrHole = (e) => {
      if (e.target.value === 'select') {
         return null;
      }
      else {
         createPart('part', e.target.value)
      }
   }

 

   const renderTargetLayerPopup = () => {
      if (targetLayerPopup === true) {
         return (
            <div className='svgEditorPopup'>
               <div className='popup'>
                  <div className='header'>Set Target <button onClick={(e) => setTargetLayerPopup(false)}>Cancel</button></div>
                  <div className='content'>
                     Every hole must have a parent, <br /> Select the target parent (part) for the current hole.
                     <br />
                     <select className="custom-select" onChange={(e) => { handelTargetLayerSelectionFOrHole(e) }}>
                        <option value='select'>Select </option>
                        {renderPartsList()}
                     </select>
                  </div>
                  <div className='footer'>
                  </div>
               </div>
            </div>
         )
      }
   }
   console.log("showPoints: ", showPoints)

   return (
      <>
         {svgSource.error === false && svgSource.loading === false &&
            <>

               <div className='outer'>
                  <div className='layersPannel'>
                     <ul className='title'>
                        <li>
                           <img onClick={(e) => toggleLayerVisibility("all")} className='vivbilityIcon' src={(svgLayerVisibility.data.all === false) ? eyeClosed : eyeOpen} alt="Visible" />
                           {/* <span>
                              <input type="checkbox" />
                           </span> */}
                           <span>All   {moduleName !== 'config-viewer' ? " Parts" : " Layers"}</span>
                        </li>
                     </ul>

                     <ul className='paths'>
                        <PartList selectedPart = {selectedPart} setSelectedPart = {setSelectedPart}/>
                        {((moduleName === 'config-viewer' && props.preview === true) || (moduleName === 'assembly-viewer' && props.preview === false)) && <>
                           <li className='pH'>Layers</li>
                           <Layers />
                        </>
                        }
                     </ul>
                  </div>

                  {renderTargetLayerPopup()}
                  <SvgViewer 
                  resetShowPoints={(e) => setShowPoints({ status: false, index: '' })} 
                  reCalulateTransformation={(index, val, key) => reCalulateTransformations(index, val, key)} 
                  preview={props.preview} 
                  showPoints={showPoints.status} 
                  setPoints={(e, index, type) => setShowPoints({ status: !showPoints.status, index: index, type: type })} 
                  paths={getCoordinates()} 
                  createPart={(e, target) => createPart(e, target)}
                  deletePart={(e, target) => deletePart(e, target)}
                  selectionCont={Object.keys(selectedlayers).length} 
                  pStatus={pstatus} 
                  handlelayerSelection={(e, source, keyboardKey) => handlelayerSelections(e, source, keyboardKey)} 
                  selectedPart = {selectedPart} 
                  setSelectedPart = {setSelectedPart}
                  editMode={props.editMode}
                  setEditMode={props.setEditMode}
                  connectionArray={props.connectionArray}
                  setConnectionArray={props.setConnectionArray}
                  cordPairs={props.cordPairs}
                  setCordPairs={props.setCordPairs}
                  svgPathAfterEdit={props.svgPathAfterEdit}
                  setSvgPathAfterEdit={props.setSvgPathAfterEdit}
                  currentPartIndex={props.currentPartIndex}
                  setCurrentPartIndex={props.setCurrentPartIndex}
                  holeConnectionArray={props.holeConnectionArray}
                  setHoleConnectionArray={props.setHoleConnectionArray}
                  holeCordPairs={props.holeCordPairs}
                  setHoleCordPairs={props.setHoleCordPairs}
                  holeSvgPathAfterEdit={props.holeSvgPathAfterEdit}
                  setHoleSvgPathAfterEdit={props.setHoleSvgPathAfterEdit}
                  />
               </div>
            </>
         }
      </>
   );
};

export default LoadContainer;
