import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";

import "./index.css";
import cloneDeep from "lodash/cloneDeep";

function DataGrid(props) {
  let mainData = props?.tableData?.data?.rows;

  const [data, setData] = React.useState([]);
  let searchKey = useSelector((state) => state.searchKey);
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    if (searchKey !== "") {
      const _searchKey = searchKey.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();

      let filter = mainData.filter((item) =>
        props.path.includes("material") || props.path.includes("sam") || props.path.includes("fiber")
          ? (!!item["key"] && item["key"].toString().match(_searchKey)) ||
            (!!item["author"] && item["author"].toLowerCase().match(_searchKey)) ||
            (!!item["fiberName"] && item["fiberName"].replace(/_/g, "").toLowerCase().match(_searchKey)) ||
            (!!item["samName"] && item["samName"].replace(/_/g, "").toLowerCase().match(_searchKey)) ||
            (!!item["testMethods"] && item["testMethods"].replace(/[_-]/g, "").toLowerCase().match(_searchKey)) ||
            (!!item[`${props.path.split("-")[0]}Description`] &&
              item[`${props.path.split("-")[0]}Description`]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(_searchKey)) ||
            (!!item[`${props.path.split("-")[0]}Name`] &&
              item[`${props.path.split("-")[0]}Name`]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(_searchKey)) ||
            (!!item[`${props.path.split("-")[0]}Type`] &&
              item[`${props.path.split("-")[0]}Type`]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(_searchKey)) ||
            (!!item["status"] && item["status"].toLowerCase().match(_searchKey)) ||
            (!!item["vendor"] && item["vendor"].toLowerCase().match(_searchKey))
          : (props.path.includes("compression") && !!item["key"] && item["key"].toString().match(_searchKey)) ||
            (!!item["materialDescription"] &&
              item["materialDescription"]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(_searchKey)) ||
            (!!item["productCategory"] && item["productCategory"]).toLowerCase().match(_searchKey) ||
            (!!item["requestId"] && item["requestId"].toLowerCase().match(_searchKey)) ||
            (!!item["requestorName"] && item["requestorName"].toLowerCase().match(_searchKey)) ||
            (!!item["sampleId"] &&
              item["sampleId"]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(_searchKey)) ||
            (!!item["stackSpecimenCount"] && item["stackSpecimenCount"].toString().match(_searchKey)) ||
            (!!item["testState"] && item["testState"].toLowerCase().match(_searchKey))
      );
      setData(filter);
      setCurrent(1);
      return;
    } else {
      setData(mainData);
      return;
    }
  }, [mainData, props, searchKey]);

  const removeMaterialsPrefix = (path) => path.replace(/^materials\//, "");

  const renderColumns = () => {
    let columnList = cloneDeep(props.tableData.data.columns);
    if (props.path !== "calculator-details" && props.path !== "labvantage-details") {
      let tempColumns = [];
      if (columnList?.length > 0) {
        columnList.forEach((item) => {
          let tempItem = item;
          let tempKey = item["key"];

          if (item.type === "number") {
            tempItem.sorter = (a, b) => a[`${tempKey}`] - b[`${tempKey}`];
          } else if (item.type === "text" || item.type === "link") {
            tempItem.sorter = (a, b) => a[`${tempKey}`].localeCompare(b[`${tempKey}`]);
          } else if (item.type === "date") {
            tempItem.sorter = (a, b) => new Date(a[`${tempKey}`]).getTime() - new Date(b[`${tempKey}`]).getTime();
          }

          tempItem.sortDirections = ["descend", "ascend"];
          if (item.type === "link" && props.enableLink && props.enableLink === true) {
            tempItem.render = (a, b) => {
              if (tempKey === "samName") {
                const path = removeMaterialsPrefix(`/sam/sam-details/${b.key}`);
                return <Link to={`${path}`}>{a}</Link>;
              } else if (tempKey === "fiberName") {
                const path = removeMaterialsPrefix(`/fibers/fiber-details/${b.key}`);
                return <Link to={`${path}`}>{a}</Link>;
              } else if (props.path.includes("compression")) {
                return (
                  <a
                    href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/compression?id=${b.key}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="now-ui-icons arrows-1_cloud-download-93"></i> {a}
                  </a>
                );
              } else {
                return <Link to={`${props.path}/${b.key}`}>{a}</Link>;
              }
            };
          }

          tempColumns.push(tempItem);
        });

        return tempColumns;
      } else {
        return columnList;
      }
    } else {
      return columnList;
    }
  };

  const columns = renderColumns();

  const handleRowSelection = (record, rowIndex) => {
    if (props.enableRowSelection) {
      return {
        onClick: () => {
          props.onSelectRowEvent(record, rowIndex);
        },
      };
    }
  };

  return (
    <div className="col-lg-12 gridContainer">
      <div className="card">
        <div className="card-body">
          <Table
            pagination={{
              defaultPageSize: 10,
              onChange: onChange,
              current: current,
              showSizeChanger: true,
            }}
            rowSelection={props.rowSelection}
            columns={columns}
            dataSource={data}
            scroll={{
              x: true,
            }}
            onRow={(record, rowIndex) => handleRowSelection(record, rowIndex)}
          />
        </div>
      </div>
    </div>
  );
}

export default DataGrid;
