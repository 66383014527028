import { Table } from "antd";
import React from "react";

const Variables = ({ columns, data }) => (
  <Table
    size="small"
    columns={columns}
    dataSource={data}
    scroll={{
      x: "max-content",
    }}
    bordered={true}
    pagination={false}
  />
);

export default Variables;
