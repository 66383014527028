import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  updateSvgPartByKey, deletePart, updateSvgParts } from '../../axios/index'
import fullscreen from './fullscreen.svg'
import eyeClosed from './eye-closed.svg';
import eyeOpen from './eye-open.svg';
import "./index.css";
function PartList(props) {
  const dispatch = useDispatch();
  const svgViewerPartData = useSelector((state) => state.svgViewerPartData)


  const toggleLayerVisibility = (e, assembly) => {
  
  }
 
  
 const handelPartSelections = (part, index, key, val) => {
  props.setSelectedPart(index);
  dispatch(updateSvgPartByKey({index:index, key:key,data: val}))
 }
 
 const togglePartVisibility = (g, index) => {
   
 
 }
 const handelDelete=(index)=>{
  dispatch(deletePart({index:index, type:'delete'}))
 }


  const renderGroups = () => {
    if (svgViewerPartData.data.configData?.partData!==undefined &&  svgViewerPartData.data.configData.partData.length > 0) {
       return svgViewerPartData.data.configData.partData.map((part, index) => {
        if(part?.action?.delete!==undefined && part.action.delete===false){
          return (
             <li>
                <img onClick={(e) => handelPartSelections(part, index, 'partVisibility',!part.data.partVisibility)} className='vivbilityIcon' src={part.data.partVisibility===true ? eyeOpen : eyeClosed} alt="Visible" />
                <span>
                   <input type="checkbox"
                      checked={part.data.partStatus}
                      onChange={(e) => handelPartSelections(part, index, 'partStatus', !part.data.partStatus)} />
                </span>
                <span>Part {index}</span>
                <span className='deletePart' onClick={(e) => handelDelete(index)}>Delete</span>
             </li>)
        }
       })
    }
 }

  return (<>
    {/* <ul className='title'>
      <li>
        <img className='vivbilityIcon' src={eyeOpen} alt="Visible" />
        <span>
          <input type="checkbox" />
        </span>
        <span>All Layers</span>
      </li>

    </ul>

    <ul className='paths'>
      {renderGroups()}
      <hr />
      {/* {renderLayers()} */}
    {/* </ul> */}
     {/* */}  
     {renderGroups()}
  </>
  );
};

export default PartList;
