import ReactEcharts from "echarts-for-react";
import { LineChart } from "echarts/charts";
import { GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent } from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { SVGRenderer } from "echarts/renderers";
import React from "react";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  SVGRenderer,
  UniversalTransition,
  LegendComponent,
  ToolboxComponent,
]);

function Graph({ xAxisLabel, yAxisLabel, data }) {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <ReactEcharts
        echarts={echarts}
        opts={{ renderer: "svg" }}
        option={{
          tooltip: {
            show: true,
            formatter: function (params) {
              var data = params.data || [0, 0];
              return `<b style='color: ${params.color}'>${params.seriesName}</b><br>` + data[0] + ", " + data[1];
            },
          },
          legend: {
            show: true,
            top: 8,
            selectedMode: false,
          },
          grid: {
            containLabel: true,
            left: 36,
            right: 24,
            top: 48,
            bottom: 36,
          },
          xAxis: {
            name: xAxisLabel,
            type: "value",
            minorTick: {
              show: true,
            },
            minorSplitLine: {
              show: true,
            },
            nameLocation: "middle",
            nameGap: 36,
          },
          yAxis: {
            name: yAxisLabel,
            type: "value",
            minorTick: {
              show: true,
            },
            minorSplitLine: {
              show: true,
            },
            nameLocation: "middle",
            nameGap: 36,
          },
          series: data,
          dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0],
              filterMode: "none",
            },
            {
              type: "inside",
              yAxisIndex: [0],
              filterMode: "none",
            },
          ],
          animation: false,
        }}
        lazyUpdate={true}
        style={{ height: "calc(100vh - 240px)" }}
      />
    </div>
  );
}

export default Graph;
