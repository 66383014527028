import instance from "./config";

instance.interceptors.request.use(
  function (config) {
    let obj = JSON.parse(localStorage.getItem("okta-token-storage"));
    if (obj && obj.hasOwnProperty("idToken")) {
      let idToken = obj.idToken.idToken;
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Reducer to fetch Material
export const getMaterialSummarydata = (params) => async (dispatch) => {
  let request = await instance
    .get(`/homepage/getMaterialSummaryData?user=${params.user}`)
    .then((res) => {
      dispatch({ type: "GET_MATERIAL_SUMMARY_DATA", payload: res.data });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_MATERIAL_SUMMARY_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};
//Reducer to fetch SAM
export const getSamSummaryData = (params) => async (dispatch) => {
  let request = await instance
    .get(`/homepage/getSamSummaryData?user=${params.user}`)
    .then((res) => {
      dispatch({ type: "GET_SAM_SUMMARY_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((error) => {
      dispatch({ type: "GET_SAM_SUMMARY_DATA_FAILED", payload: error });
      Promise.reject(error);
    });

  return request;
};

//Reducer to fetch Fiber
export const getFiberSummaryData = (params) => async (dispatch) => {
  let request = await instance
    .get(`/homepage/getFiberSummaryData?user=${params.user}`)
    .then((res) => {
      dispatch({ type: "GET_FIBER_SUMMARY_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_FIBER_SUMMARY_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

//Reducer to fetch Calculator
export const getCalculatorReferenceData = (params) => async (dispatch) => {
  let request = await instance
    .get(`/homepage/getCalculatorReferenceData`)
    .then((res) => {
      dispatch({ type: "GET_CALCULATOR_REFERENCE_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_CALCULATOR_REFERENCE_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};
//Reducer to fetch LabvantageReferenceData
export const getLabvantageReferenceData = (params) => async (dispatch) => {
  let request = await instance
    .get(`/homepage/getLabvantageReferenceData`)
    .then((res) => {
      dispatch({ type: "GET_LABVANTAGE_REFERENCE_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_LABVANTAGE_REFERENCE_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getMaterialData = (params) => async (dispatch) => {
  let request = await instance
    .post(`/managemodel/getMaterialData`, params)
    .then((res) => {
      dispatch({ type: "GET_MATERIAL_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_MATERIAL_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getSamlData = (params) => async (dispatch) => {
  let request = await instance
    .post(`/managemodel/getSamData`, params)
    .then((res) => {
      dispatch({ type: "GET_SAM_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_SAM_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getFiberData = (params) => async (dispatch) => {
  let request = await instance
    .post(`/managemodel/getFiberData`, params)
    .then((res) => {
      dispatch({ type: "GET_FIBER_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_FIBER_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getCompressionData = (params) => async (dispatch) => {
  let request = await instance
    .post(`/homepage/getCompressionData`, params)
    .then((res) => {
      dispatch({ type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_COMPRESSION_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getMaterialPropertyDetai = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getMaterialPropertyDetail`, params)
    .then((res) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getMaterialPropertyDetaiByID = (params) => async (dispatch) => {
  try {
    const response = await instance.post(`/detailspage/getMaterialPropertyDetailByIds`, params);
    dispatch({ type: "GET_MATERIAL_DETAIL_DATA", payload: response });
    return response; // Return the data received from the API
  } catch (error) {
    dispatch({ type: "GET_MATERIAL_DETAIL_DATA_FAILED", payload: error });
    console.error("Error fetching material property details:", error);
    throw error; // Throw error for the component to handle
  }
};



export const getFiberPropertyDetai = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getFiberPropertyDetail`, params)
    .then((res) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};
export const getSamPropertyDetai = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getSamPropertyDetail`, params)
    .then((res) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const updateSearchKey = (params) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SEARCH_KEY",
    payload: params,
  });

  return true;
};

export const resetSearchKey = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_SEARCH_KEY",
  });

  return true;
};

export const updateUserInfo = (params) => async (dispatch) => {
  dispatch({
    type: "UPDATE_USER_INFO",
    payload: params,
  });

  return true;
};

export const updateUserModel = (params) => async (dispatch) => {
  dispatch({
    type: "UPDATE_USER_MODEL",
    payload: params,
  });

  return true;
};

export const resetUserModel = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_USER_MODEL",
  });
  return true;
};

export const updateLatestVersion = (params) => async (dispatch) => {
  dispatch({
    type: "UPDATE_LATEST_VERSION",
    payload: params,
  });

  return true;
};

export const resetLatestVersion = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_LATEST_VERSION",
  });
  return true;
};

export const resetUserSearch = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_USER_SEARCH",
  });
  return true;
};

export const resetDataSet = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_MATERIAL_DETAIL_DATA",
    payload: params,
  });

  return true;
};
export const resetDataSetToInital = () => async (dispatch) => {
  dispatch({
    type: "RESET_MATERIAL_DETAIL_INITIAL",
  });

  return true;
};

export const sendRecordDataToBackEnd = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/upsertPropertyData`, params)
    .then((res) => {
      dispatch({ type: "SEND_RECORD_DATA_TO_BACKEND", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "SEND_RECORD_DATA_TO_BACKEND_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const fetchPropertyMetaData = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getMetaDataDetails`, params)
    .then((res) => {
      dispatch({ type: "FETCH_PROPERTY_META_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_PROPERTY_META_DATA_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const resetPropertyMetaData = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_PROPERTY_META_DATA",
  });

  return true;
};

export const reaetSendRecordDataToBackEndReducer = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_SEND_RECORD_DATA_TO_BACKEND",
  });

  return true;
};

export const setSendRecordDataToBackEndReducerStatus = (params) => async (dispatch) => {
  dispatch({
    type: "SET_SEND_RECORD_DATA_TO_BACKEND_STATUS",
  });

  return true;
};

export const resetMaterailSearchReucer = (params) => async (dispatch) => {
  dispatch({
    type: "RESET_MATERIAL_SEARCH_DATA_INITIAL",
  });

  return true;
};

export const fetchMaterialTypes = (params) => async (dispatch) => {
  let request = await instance
    .get(`/detailspage/getMaterialTypes`)
    .then((res) => {
      dispatch({ type: "FETCH_MATERIAL_SEARCH_DATA_TYPES", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_MATERIAL_SEARCH_DATA_TYPES_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const fetchAutorTypes = (params) => async (dispatch) => {
  let request = await instance
    .get(`/search/getAuthors`)
    .then((res) => {
      dispatch({ type: "FETCH_MATERIAL_AUTHOR_TYPES", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_MATERIAL_SEARCH_DATA_TYPES_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const fetchMaterialColumns = (params) => async (dispatch) => {
  let request = await instance
    .get(`/search/getFiltersForAdvSearch`)
    .then((res) => {
      dispatch({ type: "FETCH_MATERIAL_COLUMN_TYPES", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_FETCH_MATERIAL_COLUMN_TYPES_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const fetchsearchResultsFromMaterialDB = (params) => async (dispatch) => {
  let request = await instance
    .post(`/search/advanceMaterialSearch`, params)
    .then((res) => {
      dispatch({
        type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB",
        payload: res,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const getMaterialPropertyDetailById = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getMaterialPropertyDetail`, params)
    .then((res) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA", payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_MATERIAL_DETAIL_DATA_BY_ID_FAILED", payload: err });
      return Promise.reject(err);
    });
  return request;
};

//sam
export const fetchsearchResultsFromSamDB = (params) => async (dispatch) => {
  let request = await instance
    .post(`/search/advanceSamSearch`, params)
    .then((res) => {
      dispatch({ type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};
export const getSamPropertyDetailById = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getSamPropertyDetail`, params)
    .then((res) => {
      dispatch({ type: "GET_SAM_DETAIL_DATA_BY_ID", payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_SAM_DETAIL_DATA_BY_ID_FAILED", payload: err });
      return Promise.reject(err);
    });
  return request;
};

export const setSamData = (params) => async (dispatch) => {
  dispatch({
    type: "SET_SAM_DETAIL_DATA",
    payload: params,
  });

  return true;
};

//fiber

export const fetchsearchResultsFromFiberDB = (params) => async (dispatch) => {
  let request = await instance
    .post(`/search/advanceFiberSearch`, params)
    .then((res) => {
      dispatch({ type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};
export const fetchAverageFiberResultsFromCalculator = (params) => async (dispatch) => {
  let request = await instance
    .post(`/calculator/avgFiber`, params)
    .then((res) => {
      dispatch({
        type: "FETCH_AVERAGE_FIBER_RESULT_FROM_CALCULATOR",
        payload: res,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_AVERAGE_FIBER_RESULT_FROM_CALCULATOR_FAILED",
        payload: err.response,
      });
      return Promise.reject(err);
    });
  return request;
};
export const getFiberPropertyDetailById = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getFiberPropertyDetail`, params)
    .then((res) => {
      dispatch({ type: "GET_FIBER_DETAIL_DATA_BY_ID", payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_FIBER_DETAIL_DATA_BY_ID_FAILED", payload: err });
      return Promise.reject(err);
    });
  return request;
};

//labvantage
export const fetchQueryIdForSelectedProperty = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailspage/getLabvCalcFormDetail`, params)
    .then((res) => {
      dispatch({ type: "FETCH_QUERY_ID_FOR_SELECTED_PROPERTY", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_QUERY_ID_FOR_SELECTED_PROPERTY_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

//labvantage
export const fetchsearchResultsFromLabvantageAPi = (params) => async (dispatch) => {
  let request = await instance
    .post(`/search/labvantageSearch`, params)
    .then((res) => {
      dispatch({
        type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB",
        payload: res,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_SEARCH_RESULT_FROM_MATERIAL_DB",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};
export const resetsearchResultsFromLabvantageAPiToInintal = () => async (dispatch) => {
  dispatch({
    type: "RESET_QUERY_ID_FOR_SELECTED_PROPERTY",
  });

  return true;
};

export const fetchCalulatorApiResult = (params) => async (dispatch) => {
  let request = await instance
    .post(`/calculator/getDataFromCalc`, params)
    .then((res) => {
      dispatch({ type: "FETCH_CALCULATOR_API_RESULT", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({
        type: "FETCH_CALCULATOR_API_RESULT_FAILED",
        payload: err.response,
      });
      Promise.reject(err);
    });
  return request;
};

export const resetCalulatorApiResultToInintal = () => async (dispatch) => {
  dispatch({
    type: "RESET_CALCULATOR_API_RESULT_INITIAL",
  });

  return true;
};
export const resetCalulatorApiResult = () => async (dispatch) => {
  dispatch({
    type: "RESET_CALCULATOR_API_RESULT",
  });

  return true;
};

export const deleteProperty = (params) => async (dispatch) => {
  let request = await instance
    .post(`/detailsPage/deleteProperty`, params)
    .then((res) => {
      dispatch({ type: "DELETE_RECORD", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "DELETE_RECORD_FAILED", payload: err.response });
      Promise.reject(err);
    });
  return request;
};

export const resetDeleteProperty = () => async (dispatch) => {
  dispatch({
    type: "RESET_DELETE_RECORD",
  });

  return true;
};

export const getGraphData = () => async (dispatch) => {
  let request = await instance
    .get(`/detailspage/getGraphData`)
    .then((res) => {
      dispatch({ type: "GET_GRAPH_DATA", payload: res.data });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_GRAPH_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

///////////////// PHASE 2 APIS /////////////////////////////

//Product database landing screen

export const getProductDBScreenElements = () => async (dispatch) => {
  let query = new URLSearchParams({
    screenName: "landingScreen",
  });

  let request = await instance
    // .get(`/dropdowns?screenName=Landing Screen`)
    .get(`/landingScreen/dropdowns`, {
      params: query,
    })
    .then((res) => {
      dispatch({
        type: "GET_PRODUCT_DB_SCREEN_DATA",
        payload: res,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_PRODUCT_DB_SCREEN_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

// Upload file screen

export const getUploadScreenElements = () => async (dispatch) => {
  let query = new URLSearchParams({
    screenName: "uploadFileForm",
  });

  let request = await instance
    // .get(`/dropdowns?screenName=Landing Screen`)
    .get(`/landingScreen/dropdowns`, {
      params: query,
    })
    .then((res) => {
      dispatch({
        type: "GET_UPLOAD_SCREEN_DATA",
        payload: res,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_UPLOAD_SCREEN_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

// Get Upload screen details

export const getFileUploadOptions = () => async (dispatch) => {
  let request = await instance
    .get(`/upload/getUploadOptionsDropdowns`)
    .then((res) => {
      dispatch({
        type: "GET_UPLOAD_SCREEN_OPTIONS",
        payload: res,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_UPLOAD_SCREEN_OPTIONS_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const uploadFiles = (params, config) => async (dispatch) => {
  dispatch({ type: "GET_UPLOAD_SCREEN_DATA_LOADING_START" });

  let request = await instance
    .post(`/upload/uploadFile`, params, config)
    .then((res) => {
      dispatch({ type: "GET_UPLOAD_SCREEN_DATA_LOADING_STOP" });
      dispatch({
        type: "UPLOAD_FILE",
        payload: res,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_UPLOAD_SCREEN_DATA_LOADING_STOP" });
      // dispatch({ type: "UPLOAD_FILE_FAILED", payload: err });
      return Promise.reject(err);
    });

  return request;
};
// configuration table call

export const getConfigurationTableData = (params) => async (dispatch) => {
  dispatch({ type: "GET_CONFIG_DATA_LOADING", payload: "" });

  let request = await instance
    .post(`/landingScreen/configFileList`, params)
    .then((res) => {
      dispatch({ type: "GET_CONFIG_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      alert("We are currently experiencing a delay in fetching data due to database startup. Please allow a moment for the database to fully wake up. You can try refreshing the page, and if the issue continues, feel free to contact support for further assistance.")
      dispatch({ type: "GET_CONFIG_DATA_FAILED", payload: err?.response });
      Promise.reject(err);
    });
  return request;
};

export const getSourceJsonTableData = (params) => async (dispatch) => {
  dispatch({ type: "GET_SOURCE_JSON_DATA_LOADING", payload: "" });

  let request = await instance
    .post(`/landingScreen/sourceAssemblyList`, params)
    .then((res) => {
      dispatch({ type: "GET_SOURCE_JSON_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_SOURCE_JSON_DATA_FAILED", payload: err?.response });
      Promise.reject(err);
    });
  return request;
};

// Source/JSON ID Details screen
export const getSourceScreenElements = () => async (dispatch) => {
  let query = new URLSearchParams({
    screenName: "requestJson",
  });

  let request = await instance
    // .get(`/dropdowns?screenName=Landing Screen`)
    .get(`/landingScreen/dropdowns`, {
      params: query,
    })
    .then((res) => {
      dispatch({
        type: "GET_SOURCE_SCREEN_DATA",
        payload: res,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_SOURCE_SCREEN_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

export const getSourceDataById = (params) => async (dispatch) => {
  dispatch({ type: "GET_SOURCE_ID_DATA_LOADING", payload: "" });

  let request = await instance
    .post(`/landingScreen/sourceAssemblyList`, params)
    .then((res) => {
      dispatch({ type: "GET_SOURCE_ID_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_SOURCE_ID_DATA_FAILED", payload: err?.response });
      Promise.reject(err);
    });
  return request;
};

export const getJsonDataById = (params) => async (dispatch) => {
  dispatch({ type: "GET_JSON_ID_DATA_LOADING", payload: "" });

  let request = await instance
    .post(`/landingScreen/sourceAssemblyList`, params)
    .then((res) => {
      dispatch({ type: "GET_JSON_ID_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_JSON_ID_DATA_FAILED", payload: err?.response });
      Promise.reject(err);
    });
  return request;
};

export const requestJson = (params) => async (dispatch) => {
  dispatch({ type: "GET_REQUEST_JSON_LOADING_START" });

  let request = await instance
    .post(`/assembly/generateAssembly`, params)
    .then((res) => {
      dispatch({
        type: "REQUEST_JSON",
        payload: res,
      });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "REQUEST_JSON", payload: err });
      return Promise.reject(err?.response);
    });

  return request;
};

//  Delete Config
export const deleteConfig = (params) => async (dispatch) => {
  let request = await instance
    .post(`/landingScreen/deleteConfig`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      // dispatch({ type: "DELETE_RECORD_FAILED", payload: err.response });
      return Promise.reject(err);
    });
  return request;
};

//  Delete Container
export const deleteContainer = (params) => async (dispatch) => {
  let request = await instance
    .post(`/landingScreen/deleteContainer`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      // dispatch({ type: "DELETE_RECORD_FAILED", payload: err.response });
      return Promise.reject(err);
    });
  return request;
};

//  Delete SourceFile
export const deleteSourceFile = (params) => async (dispatch) => {
  let request = await instance
    .post(`/landingScreen/deleteSource`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      // dispatch({ type: "DELETE_RECORD_FAILED", payload: err.response });
      return Promise.reject(err);
    });
  return request;
};

// Admin dashboard landing screen

export const getAdminScreenElements = () => async (dispatch) => {
  let query = new URLSearchParams({
    screenName: "adminModule",
  });

  let request = await instance
    .get(`/landingScreen/dropdowns`, {
      params: query,
    })
    .then((res) => {
      dispatch({
        type: "GET_ADMIN_SCREEN_DATA",
        payload: res,
      });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_ADMIN_SCREEN_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

// part names table call

// export const getPartNamesTableData = () => async (dispatch) => {
//   // dispatch({ type: "GET_PART_NAMES_DATA_LOADING", payload: "" });
//   console.log("im here!!");
//   let request = await instance
//     .get(`/admin/partNames`)
//     .then((res) => {
//       dispatch({ type: "GET_PART_NAMES_DATA", payload: res });
//       Promise.resolve(res);
//     })
//     .catch((err) => {
//       dispatch({ type: "GET_PART_NAMES_DATA_FAILED", payload: err });
//       Promise.reject(err);
//     });
//   return request;
// };

// Admin Table data where params define type like region, product names etc
export const getAdminTableData = (params) => async (dispatch) => {
  dispatch({ type: "GET_ADMIN_DATA_LOADING", payload: "" });
  let request = await instance
    .get(`/admin/${params?.type}`)
    .then((res) => {
      dispatch({ type: "GET_ADMIN_DATA", payload: res });
      Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_ADMIN_DATA_FAILED", payload: err });
      Promise.reject(err);
    });
  return request;
};

//  Add Region
export const addRegion = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/newRegion`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  edit Region
export const editRegion = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/region`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  Add ProductType
export const addProductType = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/newProduct`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  edit ProductType
export const editProductType = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/productType`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  Add Part Name
export const addPartName = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/newPart`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  edit PartName
export const editPartName = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/partName`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//Reducer to fetch Assembly Data by Assembly ID

export const getAssemblyByID = (params) => async (dispatch) => {
  dispatch({ type: "GET_SVG_DATA_LOADING", payload: "" });
  dispatch({ type: "GET_PART_CATEGORIES_DATA_LOADING", payload: "" });
  dispatch({ type: "GET_SVG_PART_DATA_LOADING", payload: "" });
  dispatch({ type: "SVG_RESET_SELECTED_LAYERS", payload: "" });
  let request = await instance
    .get(`/svg/getAssemblyMetaData?assemblyID=${params.assemblyID}`)
    .then((res) => {
      dispatch({ type: "GET_SVG_DATA", payload: res });
      dispatch({ type: "GET_SVG_PART_DATA", payload: res });
      return Promise.resolve(res);
    })

    .catch((err) => {
      dispatch({ type: "GET_SVG_DATA_FAILED", payload: err });
      return Promise.reject(err);
    });

  return request;
};

export const getConfigByID = (params) => async (dispatch) => {
  dispatch({ type: "GET_SVG_DATA_LOADING", payload: "" });
  dispatch({ type: "GET_PART_CATEGORIES_DATA_LOADING", payload: "" });
  dispatch({ type: "GET_SVG_PART_DATA_LOADING", payload: "" });
  dispatch({ type: "SVG_RESET_SELECTED_LAYERS", payload: "" });
  let request = await instance
    .get(`/svg/getConfigurationMetaData?configId=${params.configID}`)
    .then((res) => {
      dispatch({ type: "GET_SVG_DATA", payload: res });
      dispatch({ type: "GET_SVG_PART_DATA", payload: res });
      return Promise.resolve(res);
    })

    .catch((err) => {
      dispatch({ type: "GET_SVG_DATA_FAILED", payload: err });
      return Promise.reject(err);
    });

  return request;
};

export const getPartCategories = (params) => async (dispatch) => {
  let request = await instance
    .get(`/svg/partType?productType=${params.configID}`)
    .then((res) => {
      dispatch({ type: "GET_PART_CATEGORIES_DATA", payload: res });
      return Promise.resolve(res);
    })

    .catch((err) => {
      dispatch({ type: "GET_PART_CATEGORIES_DATA_FAILED", payload: err });
      return Promise.reject(err);
    });

  return request;
};
export const updateSvgSelectedLayers = (params) => async (dispatch) => {
  dispatch({ type: "SVG_UPDATE_SELECTED_LAYERS", payload: params });
  return true;
};
export const resetSvgSelectedLayers = (params) => async (dispatch) => {
  dispatch({ type: "SVG_RESET_SELECTED_LAYERS", payload: params });
  return true;
};
export const deleteSvgSelectedLayer = (params) => async (dispatch) => {
  dispatch({ type: "SVG_DELETE_SELECTED_LAYERS", payload: params });
  return true;
};

export const updateSvgParts = (params) => async (dispatch) => {
  dispatch({ type: "UPDATE_SVG_PART_DATA", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const updateSvgPartByKey = (params) => async (dispatch) => {
  dispatch({ type: "UPDATE_SVG_PART_DATA_BY_KEY", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const updateAllSvgParts = (params) => async (dispatch) => {
  dispatch({ type: "UPDATE_ALL_SVG_PARTS_DATA", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const updateSvgPartByMulitpleKeys = (params) => async (dispatch) => {
  dispatch({ type: "UPDATE_SVG_PART_DATA_BY_MULTIPLE_KEY", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const updateSvgPartsMetaData = (params) => async (dispatch) => {
  dispatch({ type: "UPDATE_SVG_PART_META_DATA", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const updateLayerVisibility = (params) => async (dispatch) => {
  dispatch({ type: "SVG_UPDATE_LAYERS_VISIBILITY", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};
export const resetLayerVisibility = (params) => async (dispatch) => {
  dispatch({ type: "SVG_RESET_LAYERS_VISIBILITY", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const deletePart = (params) => async (dispatch) => {
  dispatch({ type: "DELETE_SVG_PART_DATA_BY_INDEX", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const deleteLastAction = (params) => async (dispatch) => {
  dispatch({ type: "DELETE_LAST_ACTION", payload: params });
  return true;
};

export const deleteAllActions = (params) => async (dispatch) => {
  dispatch({ type: "DELETE_ALL_ACTIONS", payload: params });
  return true;
};

export const undoLastActionForPart = (params) => async (dispatch) => {
  dispatch({ type: "UNDO_LAST_ACTION_FOR_PART", payload: params });
  return true;
};

export const undoLastActionForHole = (params) => async (dispatch) => {
  dispatch({ type: "UNDO_LAST_ACTION_FOR_HOLE", payload: params });
  return true;
};

export const saveConfig = (params) => async (dispatch) => {
  dispatch({ type: "SET_SVG_PART_DATA_LOADING", payload: params });
  let request = await instance
    .post(`/svg/generateConfig`, params)
    .then((res) => {
      dispatch({ type: "UPDATE_SVG_PART_DATA_BY_API", payload: res });
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch({ type: "GET_SVG_PART_DATA_FAILED", payload: err });
      return Promise.reject(err);
    });
  return request;
};

export const setSvgPartDataLoading = (params) => async (dispatch) => {
  dispatch({ type: "SET_SVG_PART_DATA_LOADING", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

export const resetSvgPartDataLoading = (params) => async (dispatch) => {
  dispatch({ type: "RESET_SVG_PART_DATA_LOADING", payload: params });
  // dispatch({type: "SVG_RESET_SELECTED_LAYERS",payload: {} });
  return true;
};

//  Add
export const addBusinessUnit = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/newBusinessUnit`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  edit BusinessUnit
export const editBusinessUnit = (params) => async (dispatch) => {
  let request = await instance
    .post(`/admin/businessUnitEdit`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  get BusinessUnit
export const getBusinessUnit = () => async (dispatch) => {
  let request = await instance
    .get(`/admin/businessUnit`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  Delete Admin Table data
export const deleteAdminTabledata = (params) => async (dispatch) => {
  let request = await instance
    .delete(`/admin/${params?.url}?id=${params?.id}`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      // dispatch({ type: "DELETE_RECORD_FAILED", payload: err.response });
      return Promise.reject(err);
    });
  return request;
};

//  Delete Assembly
export const deleteAssembly = (params) => async (dispatch) => {
  let request = await instance
    .delete(`/landingScreen/deleteAssembly?assemblyID=${params?.assemblyID}`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      // dispatch({ type: "DELETE_RECORD_FAILED", payload: err.response });
      return Promise.reject(err);
    });
  return request;
};

//  GET Material Curation Table Data
export const getMaterialCurationList = () => async () => {
  let request = await instance
    .get(`materialCuration/list`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  insert Material Curation metadata and CSV file
export const uploadCurationMetadata = (params) => async () => {
  let request = await instance
    .post(`materialCuration/uploadCSVSpecimen`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  Download specimen CSV or JSON
export const downloadSpecimenFile = (filePath) => async () => {
  let request = await instance
    .get(`materialCuration/donwloadSpecimenFile?fileName=${filePath}`, {
      responseType: "blob", // Important: ensures you get a stream
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  GET Material Curation Record by ID
export const getMaterialCurationById = (id) => async () => {
  let request = await instance
    .get(`materialCuration/curation/${id}`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  calculate Average for Specimen
export const calculateAverage = (body) => async () => {
  let request = await instance
    .post(`materialCuration/calculateAverage`, body)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  upload Finalized JSON file to blobstorage
export const uploadFinalJSON = (params) => async () => {
  let request = await instance
    .post(`materialCuration/uploadFinalJson`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getMaterialTestMethods = (id) => async () => {
  let request = await instance
    .get(`detailspage/getMaterialTestMethods/${id}`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

//  POST mechnical testmethodList Table Data
export const getTestMethodData = (testMethod) => async () => {
  try {
    const response = await instance.get(`homepage/testMethodData/${testMethod}`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//  GET mechanical testmethod option Table Data
export const getTestMethodOptions = () => async () => {
  let request = await instance
    .get(`homepage/testMethodOptions`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};

export const getCompressionRows = (params) => async () => {
  let request = await instance
    .post(`/homepage/getCompressionData`, params)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return request;
};