import { LoginCallback } from "@okta/okta-react";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import AdminPage from "../../pages/adminPage";
import Calculators from "../../pages/calulators";
import CompressionProperties from "../../pages/compressionProperties";
import Fibers from "../../pages/fibers";
import Geometry from "../../pages/geometry";
import Home from "../../pages/home";
import LabvantageAPIs from "../../pages/labvantageAPIs";
import MaterialHome from "../../pages/materialHome";
import Materials from "../../pages/materials";
import PageNotFound from "../../pages/pageNotFound";
import Sam from "../../pages/sam";
import SourceFile from "../../pages/sourceFile";
import Svg from "../../pages/svgPage";
import ToolOverview from "../../pages/tool-overview";
import ViewDetails from "../../pages/view-details";
import Loading from "./loading";
import { RequiredAuth } from "./securedRoutes";
import MaterialComparison from "../../pages/MaterialComparison";
import MechanicalProperties from "../../pages/MechanicalProperties";

export default function AppRoutes() {
  const permissions = useSelector(({ userInfo }) => userInfo?.Roles || []);

  return (
    <Routes>
      <Route path="/" element={<RequiredAuth />}>
        <Route path="" element={<Home />} />
        <Route path="/material-database" element={<MaterialHome />} />
      </Route>

      {/* <AdminRoute /> */}
      {permissions?.includes("NL_ADMIN") ? (
        <Route path="/admin" element={<RequiredAuth />}>
          <Route index element={<AdminPage />} />
        </Route>
      ) : null}
      <Route path="/geometry" element={<RequiredAuth />}>
        <Route index element={<Geometry />} />
        <Route path="/geometry/:sourceId/" element={<SourceFile />} />
        <Route path=":moduleName/:configId/" element={<Svg />} />
      </Route>
      <Route path="/materials" element={<RequiredAuth />}>
        <Route index element={<Materials />} />
        <Route path="compare" element={<MaterialComparison/>} />
        <Route path=":moduleName/:id/" element={<ViewDetails />} /> 
      </Route>
      
      <Route path="/fibers" element={<RequiredAuth />}>
        <Route index element={<Fibers />} />
        <Route path=":moduleName/:id/" element={<ViewDetails />} />
      </Route>
      <Route path="/sam" element={<RequiredAuth />}>
        <Route index element={<Sam />} />
        <Route path=":moduleName/:id/" element={<ViewDetails />} />
      </Route>
      <Route path="/mechanical-properties" element={<RequiredAuth />}>
        <Route index element={<MechanicalProperties />} />
      </Route>
      <Route path="/calculators" element={<RequiredAuth />}>
        <Route index element={<Calculators />} />
        <Route path=":moduleName/:id/" element={<ViewDetails />} />
      </Route>
      <Route path="/labvantage-apis" element={<RequiredAuth />}>
        <Route index element={<LabvantageAPIs />} />
        <Route path=":moduleName/:id/" element={<ViewDetails />} />
      </Route>
      <Route path="/compression-data" element={<RequiredAuth />}>
        <Route path="" element={<CompressionProperties />} />
      </Route>
      <Route path="/tool-overview" element={<RequiredAuth />}>
        <Route path="" element={<ToolOverview />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
      <Route path="callback" element={<LoginCallback loadingElement={<Loading />} />} />
    </Routes>
  );
}
