import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import { INITIAL_VALUE, ALIGN_LEFT, ReactSVGPanZoom, TOOL_NONE, fitToViewer, ALIGN_BOTTOM } from 'react-svg-pan-zoom';
import useContextMenu from './MenuContextHook';
import { updateSvgPartByKey, updateSvgPartByMulitpleKeys, deleteLastAction, undoLastActionForPart, undoLastActionForHole, deleteAllActions, updateAllSvgParts } from '../../axios/index'

import SvgCircle from './SvgCircle';
// import SvgZoom from "./SvgZoom"; 
import "./index.css";
import { Select } from "antd";
function SvgViewer(props) {
  const { configId, moduleName } = useParams();
  const Viewer = useRef(null);
  const dispatch = useDispatch();
  const svgSource = useSelector((state) => state.svgViewerData)
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [mousePos, setMousePos] = useState({});
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [pathPoints, setPathPoints] = useState('');
  const [tool, setTool] = useState(TOOL_NONE)
  const [value, setValue] = useState(INITIAL_VALUE)
  const [svgWidthHeight, setSvgWidthHeight] = useState({ SVGWidth: window.innerWidth - 280, SVGHeight: window.innerHeight - 150 })
  const [svgConfigWidthHeight, setSvgConfigWidthHeight] = useState({ SVGWidth: window.innerWidth - 280, SVGHeight: window.innerHeight - 150 })
  
  
  const width = window.innerWidth - 280;
  const height = window.innerHeight - 150;
  const categories = useSelector((state) => state.partCategories)
  const selectedlayer = useSelector((state) => state.svgSelectedLayers)
  const parts = useSelector((state) => state.svgViewerPartData.data.configData.partData)
  const partsMetaData = useSelector((state) => state.svgViewerPartData.data.configData.metaData)
  const undoData = useSelector((state) => state.svgUndoData.data);
  const [sourceOrDestPoint, setSourceOrDestiPoint] = useState("");
 
  console.log("undoData: ", undoData);
  console.log("***parts: ", parts);

  const [xCord, setXCord] = useState(0);
  const [yCord, setYCord] = useState(0);
  const [newXCord, setNewXCord] = useState(0);
  const [newYCord, setNewYCord] = useState(0);
  const [selectedPointType, setSelectedPointType] = useState("");
  const [newPointCords, setNewPointCords] = useState({x: 0, y: 0})
  const [sourcePoint, setSourcePoint] = useState({x: 0, y: 0})
  const [destinationPoint, setDestinationPoint] = useState({x: 0, y: 0})
  const [editOption, setEditOption] = useState("");

  //const [svgPathAfterEdit, setSvgPathAfterEdit] = useState("");

  useEffect(() => {

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  useEffect(() => {

    if (svgSource?.data?.assemblyData?.data?.ranges?.x?.max !== undefined) {
      let SVGHeight = svgSource.data.assemblyData.data.ranges.y.max - svgSource.data.assemblyData.data.ranges.y.min;
      let SVGWidth = svgSource.data.assemblyData.data.ranges.x.max - svgSource.data.assemblyData.data.ranges.x.min;
      setSvgWidthHeight({ SVGHeight: SVGHeight, SVGWidth: SVGWidth })
      centerAlignSvg('default');
    }    

  }, [svgSource, props.preview, moduleName]);



  useEffect(() => {
    if ((props.preview === false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')){
    
    if (partsMetaData?.meshType !== undefined && partsMetaData.meshType!=='') {
      let tempRanges = JSON.parse(partsMetaData.meshType)
      let SVGHeight = tempRanges.y.max - tempRanges.y.min;
      let SVGWidth = tempRanges.x.max - tempRanges.x.min;
      setSvgConfigWidthHeight({ SVGHeight: SVGHeight, SVGWidth: SVGWidth })
      centerAlignSvg('default');
    }    
  }

  }, [props.preview]);


  useEffect(()=>{
    setXCord(0);
    setYCord(0);
    setNewXCord(0);
    setNewYCord(0);
    props.setCurrentPartIndex(props.selectedPart)
  }, [props.selectedPart])
  
  useEffect(()=>{
    console.log("delete actions called")
    dispatch(deleteAllActions({}));
  }, [])

  useEffect(() => {
    if(props.editMode){
      createConnectionArray();
      createHoleConnectionArray();
      createCordPairs();
      createHoleCordPairs();
    }
  }, [props.editMode]);

  useEffect(()=>{
    let tempPart  = cloneDeep(parts); 
    dispatch(updateAllSvgParts({ data: tempPart }))
  }, [svgSource])

  useEffect(()=>{
    if(sourceOrDestPoint === "source"){
      setSourcePoint({x: xCord, y: yCord})
    }else if(sourceOrDestPoint === "destination"){
      setDestinationPoint({ x: xCord, y: yCord})
    }
  }, [xCord, yCord])

  const createConnectionArray = () => {
    const selectedPart = parts?.filter((part, index)=> part.data.partStatus == true)
    const connectionPairs = getConnectionArray(selectedPart[0].data.partTransformedCoordinates);
    props.setConnectionArray(connectionPairs);
  }

  const createHoleConnectionArray = () => {
    const selectedPart = parts?.filter((part, index)=> part.data.partStatus == true)
    const allHoleCords = selectedPart[0].data.partHoleReference.map(hole => hole.partTransformedCoordinates);
    
    let connectionPairs = [] 
    allHoleCords?.forEach((cords)=>{
      let holeConnections = getConnectionArray(cords);
      connectionPairs.push(holeConnections);
    })
    
    console.log("allHoleCords: ", allHoleCords)
    props.setHoleConnectionArray(connectionPairs);
  }

  const createCordPairs = () => {
    const selectedPart = parts?.filter((part, index)=> part.data.partStatus == true)
    let coordinatePairs = getCoordinatePairsArray(selectedPart[0].data.partTransformedCoordinates);
    props.setCordPairs(coordinatePairs);
  }

  const createHoleCordPairs = () => {
    const selectedPart = parts?.filter((part, index)=> part.data.partStatus == true)
    const allHoleCords = selectedPart[0].data.partHoleReference.map(hole => hole.partTransformedCoordinates);
    let coordinatePairs = []
    allHoleCords?.forEach((cord)=>{
      let holeCords = getCoordinatePairsArray(allHoleCords);
      coordinatePairs.push(holeCords);
    })
    props.setHoleCordPairs(coordinatePairs);
  }

  console.log("Hole Connection Array: ", props.holeConnectionArray)
  console.log("Hole Cords Array: ", props.holeCordPairs)
  console.log("Hole holeSVGPathAfterEdit: ", props.holeSvgPathAfterEdit)

  const getConnectionArray = (item) => {
    let connArray = [];
    let firstIndex = 0;
    const extractCoordinates = (data) => {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          extractCoordinates(data[i]);
          if(typeof data[0][0] === 'number'){
            if(data[0][0] == data[data.length-1][0] && data[0][1] == data[data.length-1][1] ){ 
                if(i <= data.length - 2 ){
                    if(i == data.length - 2){
                      connArray.push([i+firstIndex, firstIndex, 1])
                          firstIndex = firstIndex + data.length - 1
                    }else{
                      connArray.push([i+firstIndex, i+1+firstIndex, 1])
                    }
                }
            }else {
              if(i == data.length - 1){
                firstIndex = firstIndex + data.length
              }else{
                connArray.push([i+firstIndex, i+1+firstIndex, 1])
              }
            }
          }
        }
      } 
    };
  extractCoordinates(item);
   return connArray;
  };

  console.log("props.connectionArray: ", props.connectionArray)
  console.log("props.cordPairs: ", props.cordPairs)
  console.log("svgPathAfterEdit: ", props.svgPathAfterEdit)

  const centerAlignSvg = (e) => {
    let tempValue;
    if (e === 'default') {
      tempValue = cloneDeep(Viewer.current.getValue());
    }
    else {
      tempValue = cloneDeep(e);
    }
    if (props.preview === false && moduleName === 'config-viewer'){
      if (partsMetaData?.ranges !== undefined && partsMetaData?.ranges!=='' && partsMetaData?.ranges!== null) {
        console.log("partsMetaData?.meshType: ", partsMetaData?.meshType)
        console.log("partsMetaData: ", partsMetaData)
        let tempRanges = partsMetaData?.ranges
        tempValue.SVGHeight = tempRanges.y.max - tempRanges.y.min;
        tempValue.SVGWidth = tempRanges.x.max - tempRanges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = tempRanges.x.min;
        tempValue.SVGMinY = -Math.abs(tempRanges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    else if((props.preview === true && moduleName === 'assembly-viewer')){
      if (partsMetaData?.meshType !== undefined && partsMetaData.meshType!=='') {
        let tempRanges = JSON.parse(partsMetaData.meshType)
        tempValue.SVGHeight = tempRanges.y.max - tempRanges.y.min;
        tempValue.SVGWidth = tempRanges.x.max - tempRanges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = tempRanges.x.min;
        tempValue.SVGMinY = -Math.abs(tempRanges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    else{
      if (svgSource?.data?.assemblyData?.data?.ranges?.x?.max !== undefined) {
        tempValue.SVGHeight = svgSource.data.assemblyData.data.ranges.y.max - svgSource.data.assemblyData.data.ranges.y.min;
        tempValue.SVGWidth = svgSource.data.assemblyData.data.ranges.x.max - svgSource.data.assemblyData.data.ranges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = svgSource.data.assemblyData.data.ranges.x.min;
        tempValue.SVGMinY = -Math.abs(svgSource.data.assemblyData.data.ranges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    //?svgConfigWidthHeight.SVGWidth:svgWidthHeight.SVGWidth} height={props.preview===false && moduleName === 'config-viewer'?svgConfigWidthHeight.SVGHeight:svgWidthHeight.SVGHeight}

    
  }







  const miniatureProps = { position: 'none' }

  const handelOriginPointRadius = () => {
    //--point-radius
    if (Viewer.current) {
     
      const viewerInstance = Viewer.current;
      const newRadius = 2 / viewerInstance.getValue().a;
      document.documentElement.style.setProperty('--point-radius', newRadius * 1.5);
      document.documentElement.style.setProperty('--point-stroke', newRadius * 1.5);
      console.log("viewerInstance.getValue():",viewerInstance.getValue())

    }
  };

  const handleMouseMove = (event) => {

  };


  const handleClick = (event) => {
    // layer selection
    event.stopPropagation();
    let keyboardKey = 'none'
    if (event.originalEvent.ctrlKey) {
      keyboardKey = 'ctrlKey';
    }
    else if (event.originalEvent.altKey) {
      keyboardKey = 'altKey';
    }
    if (event.originalEvent.target.nodeName === 'path') {

      props.handlelayerSelection(event.originalEvent.target, 'svgViewer', keyboardKey)


    }

  }

  const updateCoordinateEdit = (e, cord, pointType) => {
    console.log("updateCoordinateEdit: ", cord, pointType);
    setSelectedPointType(pointType);
    setXCord(Number(cord[0]));
    setYCord(Number(cord[1]));
    setNewXCord(Number(cord[0]));
    setNewYCord(Number(cord[1]));
  }

  // const getConvertedTransformedSVGPath = (coordinates) => {

  //   let transformedCoordinates = coordinates;
  //   let partTransformedSvgPath = "";
  //   transformedCoordinates.map((allCord)=>{
  //   allCord?.map((sourcePoint, sourceIndex) => {
  //       let newPath = sourceIndex === 0 ? 'M' : '';
  //       sourcePoint.map((coordinates, groupIndex) => {
  
  //         if (sourceIndex === 0) {                 
  //             newPath = newPath + ' ' + coordinates[0] + ', ' + coordinates[1];
  //         }
  //         else {
  //             let newPath2 = 'M';
  //             coordinates.map((pointCoordinates, pointCoordinatesIndex) => {
  //               newPath2 = newPath2 + ' ' + pointCoordinates[0] + ', ' + pointCoordinates[1];
  //             })
  //             newPath = newPath + newPath2 + 'Z'
  //         }
  //       })
  //       if ((newPath.trim() !== '') && (newPath.trim() !== 'M')) {
  //         partTransformedSvgPath = partTransformedSvgPath + newPath;
  //       }
  
  //     })
  //   })
  //   return partTransformedSvgPath;
  // }

  const getCoordinatePairsArray = (coordinates) => {
    let coordinatePairs = [];
  
    // Recursive function to extract coordinates and create lines
    const extractCoordinates = (data) => {
      if (Array.isArray(data)) {
        let pairs = [];
  
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
  
          if (Array.isArray(item)) {
            if (item.length === 2 &&
                typeof item[0] === 'number' && typeof item[1] === 'number') {
              pairs.push(item); // Collect valid coordinate pairs
            } else {
              // Recursively handle nested arrays
              extractCoordinates(item);
            }
          }
        }
  
        // Handle exclusion of the last pair if it matches the first one
        if (pairs.length > 1) {
          const firstPair = pairs[0];
          const lastPair = pairs[pairs.length - 1];
          
          // Exclude last pair if it matches the first one
          if (JSON.stringify(firstPair) === JSON.stringify(lastPair)) {
            pairs.slice(0, -1).forEach(pair => coordinatePairs.push(pair));
          } else {
            pairs.forEach(pair => coordinatePairs.push(pair));
          }
        } else {
          // If there's only one or no valid pair, just add them
          pairs.forEach(pair => coordinatePairs.push(pair));
        }
      }
    };
  
    extractCoordinates(coordinates);
    console.log("coordinates: ", coordinates)
    return coordinatePairs;
  };
  

const renderPointsEdit = (item) => {
  let coordinatePairs = [];

  const extractCoordinates = (data) => {
    if (Array.isArray(data)) {
      if (data.length === 2 && typeof data[0] === 'number' && typeof data[1] === 'number') {
        coordinatePairs.push(data);
      } else {
        for (let i = 0; i < data.length; i++) {
          extractCoordinates(data[i]);
        }
      }
    } else if (typeof data === 'number') {
      coordinatePairs.push(data);
    }
  };

  extractCoordinates(item);
  //console.log("coordinatePairs:", coordinatePairs); // Log the coordinate pairs for debugging
  return coordinatePairs;
};

const updateCoordinate = (data, targetX, targetY, newX, newY) => {
  const updateCoordinateHelper = (item) => {
    if (Array.isArray(item)) {
      // Check if the array has exactly two numeric elements (coordinate pair)
      if (item.length === 2 && typeof item[0] === 'number' && typeof item[1] === 'number') {
        // Check if this is the target coordinate pair
        if (item[0] === targetX && item[1] === targetY) {
          // Update the coordinate pair with new values
          return [newX, newY];
        }
      } else {
        // Recursively update nested arrays
        return item.map(subitem => updateCoordinateHelper(subitem));
      }
    }
    // Return non-array values as-is
    return item;
  };

  // Call the helper function to update the coordinate in data
  return updateCoordinateHelper(data);
};





const deleteCoordinate = (data, targetX, targetY) => {
  const deleteCoordinateHelper = (item) => {
    if (Array.isArray(item)) {
      // Check if the array has exactly two numeric elements (coordinate pair)
      if (item.length === 2 && typeof item[0] === 'number' && typeof item[1] === 'number') {
        // Check if this is the target coordinate pair
        if (item[0] === targetX && item[1] === targetY) {
          // Return null to indicate deletion of this coordinate pair
          return null;
        }
      } else {
        // Recursively update nested arrays
        return item.map(subitem => deleteCoordinateHelper(subitem)).filter(Boolean); // Filter out null values
      }
    }
    // Return non-array values as-is
    return item;
  };

  // Call the helper function to delete the coordinate from data
  return deleteCoordinateHelper(data);
};


const getLinesWithArrows = (coordinatePairs) => {
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a || 1; // Default to 1 if `a` is undefined
  let allLines = [];

  // Function to compute mid-points
  const getMidPoint = (x1, y1, x2, y2) => ({
    xMid: (x1 + x2) / 2,
    yMid: (y1 + y2) / 2,
  });

  // Recursive function to extract coordinates and create lines
  const extractCoordinates = (data) => {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const point1 = data[i];
        const point2 = data[(i + 1) % data.length]; // Loop back to start to close the polygon

        if (Array.isArray(point1) && point1.length === 2 &&
            typeof point1[0] === 'number' && typeof point1[1] === 'number' &&
            Array.isArray(point2) && point2.length === 2 &&
            typeof point2[0] === 'number' && typeof point2[1] === 'number') {
          
          const midPoints = getMidPoint(point1[0], point1[1], point2[0], point2[1]);

          allLines.push(
            <g key={`line-${point1[0]}-${point1[1]}-${point2[0]}-${point2[1]}`}>
              <defs>
                <marker
                  id="arrowForLine"
                  viewBox="0 0 10 10"
                  refX="5"
                  refY="5"
                  markerWidth={factor + 5}
                  markerHeight={factor + 5}
                  stroke="grey"
                  orient="auto-start-reverse"
                  fill="#fff">
                  <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker>
              </defs>
              <line
                x1={point1[0]}
                y1={point1[1]}
                x2={midPoints.xMid}
                y2={midPoints.yMid}
                stroke="blue"
                strokeWidth={factor}
                markerEnd="url(#arrowForLine)" />
            </g>
          );
        } else if (Array.isArray(point1)) {
          // Recursively handle nested arrays
          extractCoordinates(point1);
        }
      }
    }
  };

  extractCoordinates(coordinatePairs); // Start processing

  console.log("allLines:", allLines); // Debugging line

  return allLines; // Return the array of lines with arrows
};

const calculateSVGPathAfterEdit = (conArray, cordsArray) => {
  let newPath = ""
  conArray?.forEach((item, i) => {
    let firstCord = conArray[i][0]
    let secondCord = conArray[i][1]
    newPath = newPath + `M ${cordsArray[firstCord][0]}, ${cordsArray[firstCord][1]} ${cordsArray[secondCord][0]}, ${cordsArray[secondCord][1]}`
  })

  props.setSvgPathAfterEdit(newPath);
}

const calculateSVGPathAfterEditForHole = (conArray, cordsArray, holeIndex) => {
  let newPath = ""
  conArray[holeIndex]?.forEach((item, i) => {
    let firstCord = conArray[i][0]
    let secondCord = conArray[i][1]
    let x1 = cordsArray[holeIndex][firstCord][0];
    let y1 = cordsArray[holeIndex][firstCord][1];
    let x2 = cordsArray[holeIndex][secondCord][0];
    let y2 = cordsArray[holeIndex][secondCord][1]

    newPath = newPath + `M ${x1}, ${y1} ${x2}, ${y2}`
  })

  let holeSvgPaths = props.holeSvgPathAfterEdit;
  holeSvgPaths[holeIndex] = newPath;
  props.setHoleSvgPathAfterEdit(holeSvgPaths);
}

console.log("editOption: ", editOption);

const handleSelection = (e, partIndex, holeIndex, type, connection, x1, y1, x2, y2) => {

  console.log("current connection: ", connection, x1, y1, x2, y2)
  let tempPart  = cloneDeep(parts);    
  let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
  

  if(editOption === "delete_line" && type === "part"){
    const updatedConnectionArray = props.connectionArray?.filter(
      (arr) => !arr.every((val, index) => val === connection[index])
    );
    console.log("updatedConnectionArray: ", updatedConnectionArray)
    props.setConnectionArray(updatedConnectionArray);
    calculateSVGPathAfterEdit(updatedConnectionArray, props.cordPairs);
    dispatch(undoLastActionForPart({ actionName: "delete_line", data: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'part', connections: props.connectionArray }))
  
  }else if(editOption === "delete_line" && type === "hole"){
    const updatedConnectionArray = props.holeConnectionArray[holeIndex]?.filter(
      (arr) => !arr.every((val, index) => val === connection[index])
    );
    console.log("updatedConnectionArray: ", updatedConnectionArray)
    let holeConArray = props.holeConnectionArray;
    holeConArray[holeIndex] = [...updatedConnectionArray]
    props.setHoleConnectionArray(holeConArray);
    calculateSVGPathAfterEditForHole(updatedConnectionArray, props.holeCordPairs, holeIndex);
    dispatch(undoLastActionForPart({ actionName: "delete_line", data: props.holeCordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'hole', connections: props.holeConnectionArray }))
  
  }else if(editOption === "add_point_on_path" && type === "part"){
    let newPointIndex = props.cordPairs.length;
    let xMid = (x1 + x2) / 2;
    let yMid = (y1 + y2) / 2;
    setNewPointCords({x: xMid, y: yMid});
    let connectionPairs = [...props.connectionArray]
    const pairIndex = connectionPairs?.findIndex(pair => pair[0] === connection[0] && pair[1] === connection[1]);
    if (pairIndex !== -1) {
      connectionPairs.splice(pairIndex, 1);
    }
    dispatch(undoLastActionForPart({ actionName: "add_point_on_path", data: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'part', connections: props.connectionArray }))
    let newCordPairsArray = [...props.cordPairs, [Number(xMid), Number(yMid)]];
    props.setCordPairs(newCordPairsArray)
    let newConArray = [...connectionPairs, [connection[0], newPointIndex, 1], [newPointIndex, connection[1], 1]];
    props.setConnectionArray(newConArray);
    calculateSVGPathAfterEdit(newConArray, newCordPairsArray);

  }else if(editOption === "add_point_on_path" && type === "hole"){
    let newPointIndex = props.holeCordPairs.length;
    let xMid = (x1 + x2) / 2;
    let yMid = (y1 + y2) / 2;
    setNewPointCords({x: xMid, y: yMid});
    let connectionPairs = [...props.holeConnectionArray]
    const pairIndex = connectionPairs?.findIndex(pair => pair[0] === connection[0] && pair[1] === connection[1]);
    if (pairIndex !== -1) {
      connectionPairs.splice(pairIndex, 1);
    }
    dispatch(undoLastActionForPart({ actionName: "add_point_on_path", data: props.holeCordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'hole', connections: props.holeConnectionArray }))
    let newCordPairsArray = [...props.holeCordPairs, [Number(xMid), Number(yMid)]];
    props.setHoleCordPairs(newCordPairsArray)
    let newConArray = [...connectionPairs, [connection[0], newPointIndex, 1], [newPointIndex, connection[1], 1]];
    props.setHoleConnectionArray(newConArray);
    calculateSVGPathAfterEdit(newConArray, newCordPairsArray);
  }
};


const getLines = (partIndex, conArray, cordsArray, type) => {
  
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a;
  let lines = [];
  conArray?.forEach((con, index)=>{
   
      let currentCordIndex = conArray[index][0];
      let nextCordIndex = conArray[index][1];
      lines.push( 
      <g className="editModeLines" key={con}>
        <path 
        cursor="pointer" 
        strokeWidth="2" 
        stroke-width={factor * 2} 
        onClick={(e)=>handleSelection(e, partIndex, null, type, con, cordsArray[currentCordIndex][0], cordsArray[currentCordIndex][1], cordsArray[nextCordIndex][0], cordsArray[nextCordIndex][1])}
        stroke= {type == "part" ? "blue" : "grey"}
        d={`M ${cordsArray[currentCordIndex][0]}, ${cordsArray[currentCordIndex][1]} ${cordsArray[nextCordIndex][0]}, ${cordsArray[nextCordIndex][1]}`}/>
      </g>
      )
  })
  
  console.log("lines: ", lines)

  return lines;
}

const getLinesForHoles = (partIndex, holeConArray, holeCordsArray, type) => {
  
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a;
  let lines = [];

  holeConArray.forEach((conArray, holeIndex)=>{
      conArray?.forEach((con, index)=>{
    
        let currentCordIndex = conArray[index][0];
        let nextCordIndex = conArray[index][1];
        let x1 = holeCordsArray[holeIndex][currentCordIndex][0];
        let y1 = holeCordsArray[holeIndex][currentCordIndex][1];
        let x2 = holeCordsArray[holeIndex][nextCordIndex][0];
        let y2 = holeCordsArray[holeIndex][nextCordIndex][1];
        lines.push( 
        <g className="editModeLines" key={con}>
          <path 
          cursor="pointer" 
          strokeWidth="2" 
          stroke-width={factor * 2} 
          onClick={(e)=>handleSelection(e, partIndex, holeIndex, type, con, x1, y1, x2, y2)}
          stroke= {type == "part" ? "blue" : "grey"}
          d={`M ${x1}, ${y1} ${x2}, ${y2}`}/>
        </g>
        )
    })
  })
  
  console.log("lines: ", lines)

  return lines;
}

const getOriginForConfig = () => {
  const viewerInstance = Viewer?.current;
  const factor = 2 / viewerInstance?.getValue().a;

  return(
      <>
        {/* Horizontal Line */}
        <line x1={-8 * factor} y1="0" x2={8 * factor} y2="0" stroke="white" stroke-width={factor}/>
        {/* Vertical Line */}
        <line x1="0" y1={-8 * factor} x2="0" y2={8 * factor} stroke="white" stroke-width={factor}/>
      </>
  )
}

  const renderParts = () => {
    console.log("renderParts called: ", parts);
    console.log("props.showPoints: ", props.showPoints);

    const viewerInstance = Viewer?.current;
    const factor = 2 / viewerInstance?.getValue().a;
   
    if (parts.length > 0 && props.showPoints === false) {
    
      let showOrigin = false;
      return parts.map((part, index) => {
        let cls = 'part'
        if (part.data.partStatus) {
          cls = 'part selected'
        }
        if (part.data.partVisibility && part.action.delete === false) {
          let hole = '';
          let color = part.data.partType === '' ? 'rgb(9 109 217 / 63%)' : renderColor(part.data.partType);

          if (part.data.partHoleReference.length > 0) {
            part.data.partHoleReference.map((tempHole, tempHoleIndex) => {
              if ((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) {
                hole = tempHoleIndex === 0 ? hole + tempHole.partSourceSvgPath : hole + 'z' + tempHole.partSourceSvgPath;
              }
              else {
                hole = tempHoleIndex === 0 ? hole + tempHole.partTransformedSvgPath : hole + 'z' + tempHole.partTransformedSvgPath;
              }

            })
          }
          if (part.data.partSourceSvgPath.trim() !== '') {
            let tempPath = '';
            if ((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) {

              showOrigin = true;
              tempPath = part.data.partSourceSvgPath;
            }
            else {
              tempPath = part.data.partTransformedSvgPath;
            }
            if (hole.trim() !== '') {
              tempPath = tempPath + 'z' + hole + 'z'
            }
            else {
              tempPath = tempPath + 'Z'
            }

            console.log("***tempPath: ", tempPath)
          
            return (
            <g className={cls} id='uio' fill={color} stroke='blue' cursor="default" pointerEvents="all" >
             {!props.editMode && <path data-pathType='part' data-parentIndex={index} data-childIndex={-1} vectorEffect="non-scaling-stroke" cursor="pointer" strokeWidth="2" d={tempPath} />}
              {showOrigin === true && part.data.partStatus === true &&
                <circle fill="#ff0000" stroke="none" cx={part.data.partCalculatedOrigin.x} cy={part.data.partCalculatedOrigin.y} r={factor * 1.5}>
                  <animate attributeName="opacity" dur="2s" values="0.5;1;0.5;" repeatCount="indefinite" begin="0.1" />
                </circle>
              }

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && !props.editMode
                && (editOption === "" || editOption === "delete_point" || editOption === "update_cords" || editOption === "delete_hole")
                && getLinesWithArrows(part.data.partTransformedCoordinates)
              }    

              {/* Render lines for part in edit mode */}
              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && props.editMode
                && getLines(index, props.connectionArray, props.cordPairs, "part")
              } 

              {/* Render lines for wholes in edit mode */}
              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && props.editMode
                && part.data.partHoleReference.length > 0
                && getLinesForHoles(index, props.holeConnectionArray, props.holeCordPairs, "hole")
              } 

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && props.cordPairs?.map((cord, i)=>
                  <circle 
                  key={`point-${i}`} //Added key prop for React lists
                  data-type='point' 
                  className='configPoint' 
                  onClick={(e) => updateCoordinateEdit(e, cord, "part")} 
                  cx={cord[0]} 
                  cy={cord[1]} 
                  stroke={(cord[0] == xCord && cord[1] == yCord) ? "yellow" : "red"} 
                  fill={(cord[0] == xCord && cord[1] == yCord) ? "yellow" : "red"} 
                  stroke-width="1"
                  // r="0.5" 
                  />
              )}

              {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && part.data.partStatus
                && part.data.partHoleReference.length > 0 
                && part.data.partHoleReference.map((hole, h) => renderPointsEdit(hole.partTransformedCoordinates) 
                    .map((cord, i) => (
                        <circle
                          key={`point-${i}`} //Added key prop for React lists
                          data-type='point'
                          className='configPoint'
                          onClick={(e) => updateCoordinateEdit(e, cord, "hole")}
                          cx={cord[0]}
                          cy={cord[1]}
                          stroke={cord[0] == xCord && cord[1] == yCord ? "yellow" : "red"}
                          fill={cord[0] == xCord && cord[1] == yCord ? "yellow" : "red"}
                          stroke-width="1" // Changed to camelCase for strokeWidth
                          // r="0.5"
                        />
                    )))
              }
            </g>)
          }
        }
      })
    }
  }
  const handelOriginSelection = (e, index, selText, type) => {
    if (type === 'reset') {
      let newdataSet = {
        partOriginMetaData: [],
        partCalculatedOrigin: { x: 0, y: 0 }
      }
      dispatch(updateSvgPartByMulitpleKeys({ index: index, data: newdataSet }))
    }
    else {
      props.setPoints(e, index, selText)
    }
  }
  const renderCategeories = () => {
    if (categories.loading === false && categories.data.length > 0) {
      return categories.data.map((item, index) => {
        return (
          <option key={item.id} value={item.id}>{item.name}</option>
        )
      })
    }

  }
  const handelPartSelections = (part, index, key, val) => {
   // handelPartSelections(part, index, 'partType', e.target.value)}
    if (key === 'partDirection') {
      props.reCalulateTransformation(index, val, key)
    }
    else {
      dispatch(updateSvgPartByKey({ index: index, key: key, data: val }))
    }
  }
  const renderColor = (type) => {
    let color = '(9 109 217 / 63%)'
    if (categories.loading === false && categories.data.length > 0) {
      categories.data.map((item, index) => {
        if (type == item.id) {

          color = item.color
        }
      })
    }
    color = 'rgb' + color;
    return color;
  }

  const handleChangeXcord = (e) => {
    setNewXCord(e.target.value);
  }

  const handleChangeYcord = (e) => {
    setNewYCord(e.target.value);
  }

  const replaceMatchingPairs = () => {
    // Iterate through the array to find and replace matching pairs
    return props.cordPairs?.map(pair => {
        // Check if the current pair matches the oldPair
        if (pair[0] === Number(xCord) && pair[1] === Number(yCord)) {
            // Replace with newPair
            return [Number(newXCord), Number(newYCord)];
        }
        return pair;
    });
  }



  console.log("1cordinates: ", xCord, yCord, "**", newXCord, newYCord)

  const handleUpdatePoint = (e, partIndex) => {
    let tempPart  = cloneDeep(parts);
    let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
    const upadtedCoordinates = replaceMatchingPairs();
    console.log("123upadtedCoordinates: ", upadtedCoordinates)
    dispatch(undoLastActionForPart({ actionName: "update_point", data: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connections: props.connectionArray }))
    props.setCordPairs([...upadtedCoordinates]);
    setXCord(Number(newXCord));
    setYCord(Number(newYCord));
    calculateSVGPathAfterEdit(props.connectionArray, upadtedCoordinates);

    // let tempPart  = cloneDeep(parts); 
  
    // if(selectedPointType == "part"){
    //   let currentCordinates = [...tempPart[partIndex]?.data?.partTransformedCoordinates];
    //   let currentSvgPath = tempPart[partIndex].data.partTransformedSvgPath;
    //   const updatedArray = updateCoordinate(tempPart[partIndex]?.data?.partTransformedCoordinates, Number(xCord), Number(yCord), Number(newXCord), Number(newYCord));
    //   tempPart[partIndex].data.partTransformedCoordinates = [...updatedArray];
    //   tempPart[partIndex].data.partTransformedSvgPath = getConvertedTransformedSVGPath(tempPart[partIndex].data.partTransformedCoordinates);
    //   setXCord(Number(newXCord));
    //   setYCord(Number(newYCord));
    //   dispatch(updateSvgPartByKey({ index: partIndex, key: "partTransformedCoordinates", data: tempPart[partIndex].data.partTransformedCoordinates }))
    //   dispatch(updateSvgPartByKey({ index: partIndex, key: "partTransformedSvgPath", data: tempPart[partIndex].data.partTransformedSvgPath }))
    //   dispatch(undoLastActionForPart({ actionName: "update_point", data: currentCordinates, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connections: props.connectionArray }))

    // }else if (selectedPointType === "hole") {
    //   let currentHoleData  = cloneDeep(tempPart[partIndex]?.data?.partHoleReference);
    //   tempPart[partIndex]?.data?.partHoleReference?.forEach((hole, i) => {
    //     const updatedArray = updateCoordinate(hole?.partTransformedCoordinates, Number(xCord), Number(yCord), Number(newXCord), Number(newYCord));
    //     if (hole) {
    //       hole.partTransformedCoordinates = [...updatedArray];
    //       hole.partTransformedSvgPath = getConvertedTransformedSVGPath(hole.partTransformedCoordinates);
    //     }
    //   });
    //   setXCord(Number(newXCord));
    //   setYCord(Number(newYCord));
    //   dispatch(updateSvgPartByKey({ index: partIndex, key: "partHoleReference", data: tempPart[partIndex]?.data?.partHoleReference }));
    //   dispatch(undoLastActionForHole({ actionName: "update_point", data: currentHoleData, partIndex: partIndex, pointType: selectedPointType, connections: props.connectionArray }));
     
    // }
   
  }

  const replaceWithEmptyArray = () => {
    if (!props.cordPairs) {
        return [];
    }

    // Use map to iterate over the array and replace matching pairs with an empty array
    return props.cordPairs.map(pair => {
        if (pair[0] === Number(xCord) && pair[1] === Number(yCord)) {
            // Replace matching pair with an empty array
            return [];
        }
        // Keep the original pair if it doesn't match
        return pair;
    });
  };

  const handleDeletePoint = (e, partIndex) => {
    let tempPart  = cloneDeep(parts);
    let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
    const pairsAfterDelete = replaceWithEmptyArray();
    dispatch(undoLastActionForPart({ actionName: "delete_point", data: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connections: props.connectionArray }))
    const indexOfPair = props.cordPairs.findIndex(pair => pair[0] === Number(xCord) && pair[1] === Number(yCord))
    const updatedConnectionArray = props.connectionArray.filter(con => con[0] !== indexOfPair && con[1] !== indexOfPair)
    props.setConnectionArray([...updatedConnectionArray]);
    props.setCordPairs([...pairsAfterDelete]);
    calculateSVGPathAfterEdit(updatedConnectionArray, pairsAfterDelete);


    // let tempPart  = cloneDeep(parts);    

    // if(selectedPointType == "part"){
    //   let currentCordinates = [...tempPart[partIndex]?.data?.partTransformedCoordinates];
    //   let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
    //   const cordinatesAfterDelete = deleteCoordinate(tempPart[partIndex]?.data?.partTransformedCoordinates, Number(xCord), Number(yCord));
    //   tempPart[partIndex].data.partTransformedCoordinates = [...cordinatesAfterDelete];
    //   tempPart[partIndex].data.partTransformedSvgPath = getConvertedTransformedSVGPath(tempPart[partIndex].data.partTransformedCoordinates);
    //   dispatch(updateSvgPartByKey({ index: partIndex, key: "partTransformedCoordinates", data: tempPart[partIndex].data.partTransformedCoordinates }))
    //   dispatch(updateSvgPartByKey({ index: partIndex, key: "partTransformedSvgPath", data: tempPart[partIndex].data.partTransformedSvgPath }))
    //   dispatch(undoLastActionForPart({ actionName: "delete_point", data: currentCordinates, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connections: props.connectionArray }))
     
    // }else if(selectedPointType == "hole"){
    //   setSelectedPointType("");
    //   let currentHoleData  = cloneDeep(tempPart[partIndex]?.data?.partHoleReference);    
    //   tempPart[partIndex]?.data?.partHoleReference?.forEach((hole, i) => {
    //     const cordinatesAfterDelete = deleteCoordinate(hole?.partTransformedCoordinates, Number(xCord), Number(yCord));
    //     if (hole) {
    //       hole.partTransformedCoordinates = [...cordinatesAfterDelete];
    //       hole.partTransformedSvgPath = getConvertedTransformedSVGPath(hole.partTransformedCoordinates);
    //     }
    //   });
    
    //   dispatch(updateSvgPartByKey({ index: partIndex, key: "partHoleReference", data: tempPart[partIndex]?.data?.partHoleReference }));
    //   dispatch(undoLastActionForHole({ actionName: "delete_point", data: currentHoleData, partIndex: partIndex, pointType: selectedPointType, connections: props.connectionArray }));
    // }
    setXCord(0);
    setYCord(0);
    setNewXCord(0);
    setNewYCord(0);
  }

  const handleUndo = () => {
    if(undoData.length > 0){
      let tempPart  = cloneDeep(parts);  
    
      if(undoData[undoData.length - 1].pointType == "part"){
        let { actionName, pointCoordinates, partIndex, svgPath, connections } = undoData[undoData.length - 1];
        props.setConnectionArray([...connections]);
        props.setCordPairs([...pointCoordinates]);
        // tempPart[partIndex].data.partTransformedCoordinates = [...pointCoordinates];
        // tempPart[partIndex].data.partTransformedSvgPath = svgPath;
        if(actionName === "add_point_on_path"){
          setNewPointCords({x: 0, y: 0});
        }
        //dispatch(updateSvgPartByKey({ index: partIndex, key: "partTransformedCoordinates", data: tempPart[partIndex].data.partTransformedCoordinates }))
        //dispatch(updateSvgPartByKey({ index: partIndex, key: "partTransformedSvgPath", data: tempPart[partIndex].data.partTransformedSvgPath }))
       
      }else if(undoData[undoData.length - 1].pointType == "hole"){
        let {actionName, holeData, partIndex, connections } = undoData[undoData.length - 1];
        if(actionName === "add_point_on_path"){
          setNewPointCords({x: 0, y: 0});
        }
        props.setConnectionArray([...connections])
        console.log("holeData, partIndex: ",holeData, partIndex)
        tempPart[partIndex].data.partHoleReference = [...holeData];
        dispatch(updateSvgPartByKey({ index: partIndex, key: "partHoleReference", data: tempPart[partIndex].data.partHoleReference }))
      }

      dispatch(deleteLastAction({}));
      setXCord(0);
      setYCord(0);
      setNewXCord(0);
      setNewYCord(0);
     
    }else{
      alert("No operation available for undo.")
    }
  }

  const coordinateExists = (data, targetX, targetY) => {
    console.log("coordinateExists params: ",data, targetX, targetY )
    const coordinateExistsHelper = (item) => {
      if (Array.isArray(item)) {
        // Check if the array has exactly two numeric elements (coordinate pair)
        if (item.length === 2 && typeof item[0] === 'number' && typeof item[1] === 'number') {
          // Check if this is the target coordinate pair
          if (item[0] === targetX && item[1] === targetY) {
            // Coordinate pair found
            return true;
          }
        } else {
          // Recursively check nested arrays
          for (let subitem of item) {
            if (coordinateExistsHelper(subitem)) {
              return true;
            }
          }
        }
      }
      // Coordinate pair not found in this path
      return false;
    };
  
    // Call the helper function to check if coordinate exists in data
    return coordinateExistsHelper(data);
  };
  

  const handleDeleteHole = (e, partIndex) => {
    console.log("handleDeleteHole called");
    let tempPart  = cloneDeep(parts);  
    let currentHoleData = cloneDeep(tempPart[partIndex]?.data?.partHoleReference);
    setSelectedPointType("");
    setNewXCord(0);
    setNewYCord(0);
    setXCord(0);
    setYCord(0);
    currentHoleData?.forEach((hole, holeIndex)=>{
      const isCoordinateExists = coordinateExists(hole.partTransformedCoordinates, Number(xCord), Number(yCord));
      console.log("isCoordinateExists: ", isCoordinateExists);
      if(isCoordinateExists){
        tempPart[partIndex].data.partHoleReference.splice(holeIndex, 1);
        dispatch(updateSvgPartByKey({ index: partIndex, key: "partHoleReference", data: tempPart[partIndex].data.partHoleReference }))
        dispatch(undoLastActionForHole({ actionName: "delete_hole", data: currentHoleData, partIndex: partIndex, pointType: "hole", connections: props.connectionArray }));
      }
    })

  }

  const roundDecimals = (value) => {
    let data = Number(value);
    // Rounding to 5 decimal places
    const roundedValue = Math.round(data * 100000) / 100000;
    return roundedValue;
  };

  const resetOriginSelection = (e, index, selText) => {
    if(undoData.length > 0){
      const message = "Changing the origin will result in the loss of any changes made to the part."
      // eslint-disable-next-line no-restricted-globals
      if (confirm(message) == true) {
        handelOriginSelection(e, index, selText, 'reset')
      }
    }else{
      handelOriginSelection(e, index, selText, 'reset')
    }
  
  }

  const handleChangeNewPointXCords = (e) => {
    setNewPointCords({...newPointCords, x: e.target.value} )
  }

  const handleChangeNewPointYCords = (e) => {
    setNewPointCords({...newPointCords, y: e.target.value} )
  }

  const handelEditOption = (e) => {
    setEditOption(e.target.value);
  }

  const handleSourcePointX = (e) => {
    setSourcePoint({...sourcePoint, x: e.target.value})
  }
  
  const handleSourcePointY = (e) => {
    setSourcePoint({...sourcePoint, Y: e.target.value})
  }

  const handleDestinationPointX = (e) => {
    setDestinationPoint({...destinationPoint, x: e.target.value})
  }

  const handleDestinationPointY = (e) => {
    setDestinationPoint({...destinationPoint, Y: e.target.value})
  }

  // const handleCreateConnectionAfterAddingNewPoint = (e, cords, partIndex) => {

  //   let newPointIndex = props.cordPairs.length;
  //   const newPair = [Number(newPointCords.x), Number(newPointCords.y)];
  //   // Check if the pair already exists
  //   const isPairExists = props.cordPairs?.some(item =>
  //     item[0] === newPair[0] && item[1] === newPair[1]
  //   );

  //   if(isPairExists){
  //     newPointIndex = newPointIndex - 1;
  //   }

  //   const destinationPointIndex = props.cordPairs?.findIndex(item => 
  //     item[0] === destinationPoint.x && item[1] === destinationPoint.y
  //   );

  //   dispatch(undoLastActionForPart({ actionName: "create_connection", data: cords, partIndex: partIndex, svgPath: "", pointType: selectedPointType, connections: props.connectionArray }))
  //   let newConArray = [...props.connectionArray, [newPointIndex, destinationPointIndex, 1]];
  //   props.setConnectionArray(newConArray)
   
  //   if (!isPairExists) {
  //     // Add the new pair if it doesn't already exist
  //     props.setCordPairs([...props.cordPairs, newPair]);
  //   }
  //   calculateSVGPathAfterEdit(newConArray);
  // }

  const handleCreateConnection = (e, cords, partIndex) => {
    let tempPart  = cloneDeep(parts);
    let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;

    const sourcePointIndex = props.cordPairs?.findIndex(item => 
      item[0] === sourcePoint.x && item[1] === sourcePoint.y
    );
    const destinationPointIndex = props.cordPairs?.findIndex(item => 
      item[0] === destinationPoint.x && item[1] === destinationPoint.y
    );
    
    dispatch(undoLastActionForPart({ actionName: "create_connection", data: cords, partIndex: partIndex, svgPath: currentSvgPath, pointType: selectedPointType, connections: props.connectionArray }))
    let newConArray = [...props.connectionArray, [sourcePointIndex, destinationPointIndex, 1]];
    props.setConnectionArray(newConArray);
    calculateSVGPathAfterEdit(newConArray, props.cordPairs);
  }

  const handleSourceOrDesti = (value) => {
    setSourceOrDestiPoint(value);
  }

  const handleAddNewPoint = (e, partIndex) => {
    let tempPart  = cloneDeep(parts);
    let currentSvgPath =  tempPart[partIndex].data.partTransformedSvgPath;
    //let newPointIndex = props.cordPairs.length;
   //let connectionPairs = [...props.connectionArray]
    // const pairIndex = connectionPairs?.findIndex(pair => pair[0] === connection[0] && pair[1] === connection[1]);
    // if (pairIndex !== -1) {
    //   connectionPairs.splice(pairIndex, 1);
    // }
    dispatch(undoLastActionForPart({ actionName: "add_point", data: props.cordPairs, partIndex: partIndex, svgPath: currentSvgPath, pointType: 'part', connections: props.connectionArray }))
    let newCordPairsArray = [...props.cordPairs, [Number(newPointCords.x), Number(newPointCords.y)]]
    props.setCordPairs(newCordPairsArray)
    //let newConArray = [...connectionPairs, [connection[0], newPointIndex, 1], [newPointIndex, connection[1], 1]];
    //props.setConnectionArray(newConArray);
    calculateSVGPathAfterEdit(props.connectionArray, newCordPairsArray);
  }
  

  const renderMetaDataPropertyPane = () => {
    console.log("renderMetaDataPropertyPane parts: ", parts)
    if (parts.length > 0) {
      let selText = props.showPoints === true ? 'Set' : 'Select'
      return parts.map((part, index) => {
        if (part.data.partStatus === true && part.data.partVisibility !== false) {
          let color = renderColor(part.data.partType);
          // let color ='#f9f9f9'

          return (
            <div className='propertyPane'>
              <div>
                <span className='label'>Part ID</span>
                <input type='text' className='form-control' disabled value={'part ' + index} />
              </div>
              <div>
                <span className='label'>Part Type</span>
                <select className='form-control' onChange={(e) => handelPartSelections(part, index, 'partType', e.target.value)} value={part.data.partType}>
                  <option>Select</option>
                  {renderCategeories()}
                </select>
              </div>
              <div>
                <span className='label'>Part Color</span>
                <input style={{ 'background': color }} className='form-control' readOnly={true} />
              </div>

              <div>
                <span className='label' >Part Name</span>
                <input type='text' className='form-control' value={part.data.partName} onChange={(e) => handelPartSelections(part, index, 'partName', e.target.value)} />
              </div>
              <div>
                <span className='label' >Z Value</span>
                <input 
                type='number' 
                className='form-control' 
                value={roundDecimals(part.data.partZ)} 
                onChange={(e) => handelPartSelections(part, index, 'partZ', e.target.value)} />
              </div>
              {/* <div>
          <span className='label'>Direction</span>
           <select className='form-control' >
             <option>Clockwise</option>
             <option>Anti-Clockwise</option>
           </select>
          </div> */}
              <div>
                <span className='label'>Area</span>
                <input 
                type='text' 
                value={roundDecimals(part.data.partArea) * -1} 
                className='form-control' 
                readOnly={true} />
              </div>
              <div>
                <span className='label'>Description</span>
                <input type='text' className='form-control' value={part.data.partDescription} onChange={(e) => handelPartSelections(part, index, 'partDescription', e.target.value)} />
              </div>
              <div>
                <span className='label'>Product Front</span>
                <select className='form-control' value={part.data.partDirection} onChange={(e) => handelPartSelections(part, index, 'partDirection', e.target.value)}  >
                  <option value='negativeX' key='negativeX'>Left</option>
                  <option value='positiveX' key='positiveX'>Right</option>
                  <option value='positiveY' key='positiveY'>Up</option>
                  <option value='negativeY' key='negativeY'>Down</option>
                </select>
              </div>

              <div>
                <span className='label'>Origin</span>
                <div className='origin'>
                  <div>X -{roundDecimals(part.data.partCalculatedOrigin.x)}</div>
                  <div>y -{roundDecimals(part.data.partCalculatedOrigin.y)}</div>
                  {((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) &&
                    <div className='resetOrigin'>
                      {(part.data.partOriginMetaData.length !== 0) &&
                        <button onClick={(e) => resetOriginSelection(e, index, selText)}>Reset</button>
                      }
                      <button onClick={(e) => handelOriginSelection(e, index, selText, 'select')}>{selText}</button>
                      {/* <button onClick={(e) => handelOriginSelection(e, index, selText, 'select')}>Edit</button> */}
                    </div>
                  }
                </div>
              

                {/* <div>
                  {(props.preview === true && moduleName === 'assembly-viewer') &&
                    <button onClick={(e) => handleSetPoints(e, index)}>Show Points</button>
                  }
                </div> */}
              </div>
              {props.editMode &&
                <>
                  <hr/>
                  <span style={{display: "block", border: "1px solid black", padding: "5px" }}>
                    <div className='label'>Edit Options</div>
                    <div>
                      <select className='form-control' value={editOption} onChange={handelEditOption}>
                        <option value="" >Select</option>
                        <option value='update_cords' key='update_cords'>Update Cordinates of Point</option>
                        <option value='delete_point' key='delete_point'>Delete Point</option>
                        <option value='delete_hole' key='delete_hole'>Delete Hole</option>
                        <option value='delete_line' key='delete_line'>Delete Line</option>
                        <option value='add_point' key='add_point'>Add New Point</option>
                        <option value='add_point_on_path' key='add_point_on_path'>Add New Point On Path</option>
                        <option value='create_connection' key='create_connection'>Create Connection</option>
                      </select>
                    </div>
                  </span>
                  {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  &&  (editOption === "update_cords" || editOption === "delete_point" )
                  &&  (<span style={{display: "block", border: "1px solid black", padding: "5px" }}>
                      <div className='label'>Selected Point</div>
                      <div>
                        <span className='label'>X - Cord</span>
                        <input type='text' value={roundDecimals(newXCord)} onChange = {handleChangeXcord} className='form-control' />
                      </div>
                      <div>
                        <span className='label'>Y - Cord</span>
                        <input type='text' value={roundDecimals(newYCord)} onChange = {handleChangeYcord} className='form-control' />
                      </div>
                      <div style={{marginTop: "10px"}}>
                        {editOption === "update_cords" &&
                        <button
                          className={(newXCord == xCord && newYCord == yCord) ? 'editButtonDisabled' : "editButton"}
                          disabled={(newXCord == xCord && newYCord == yCord) ? true : false }
                          onClick={(e) => handleUpdatePoint(e, index)}
                        >
                          Update Coordinates
                        </button>
                        }
                        {editOption === "delete_point" &&
                          <button
                            className={(newXCord && newYCord) ? 'editButton' : "editButtonDisabled"}
                            disabled={(newXCord && newYCord) ? false : true}
                            onClick={(e) => handleDeletePoint(e, index)}
                          >
                            Delete Point
                          </button>
                        }
                      </div> 
                  </span>
                  )}

                  { ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "add_point")
                  &&
                  (<span>
                    <div>Enter X and Y Coordinates Of New Point</div>
                    <div>
                      <span className='label'>X - Cord</span>
                      <input type='text' value={roundDecimals(newPointCords.x)} onChange = {handleChangeNewPointXCords} className='form-control' />
                    </div>
                    <div>
                      <span className='label'>Y - Cord</span>
                      <input type='text' value={roundDecimals(newPointCords.y)} onChange = {handleChangeNewPointYCords} className='form-control' />
                    </div>
                    <div>
                      <button
                      onClick={(e)=>handleAddNewPoint(e, index)}
                      >Add Point</button>
                    </div>
                  </span>
                  )}

                  {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "add_point_on_path")
                  &&
                  (<span className='label'>Click on Path to Add Point</span>
                  )}

                  {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "create_connection")
                  &&
                  (<span>
                      <span>
                          <span className='label'>
                            <button onClick={()=>handleSourceOrDesti("source")}>Select Source Point</button>
                          </span>
                          <div>
                            <span className='label'>X - Cord</span>
                            <input disabled type='text' value={roundDecimals(sourcePoint.x)} onChange = {handleSourcePointX} className='form-control' />
                          </div>
                          <div>
                            <span className='label'>Y - Cord</span>
                            <input disabled type='text' value={roundDecimals(sourcePoint.y)} onChange = {handleSourcePointY} className='form-control' />
                          </div>
                      </span>
                      <span>
                          <span className='label'>
                            <button onClick={()=>handleSourceOrDesti("destination")}>Select Destination Point</button>
                          </span>
                          <div>
                            <span className='label'>X - Cord</span>
                            <input disabled type='text' value={roundDecimals(destinationPoint.x)} onChange = {handleDestinationPointX} className='form-control' />
                          </div>
                          <div>
                            <span className='label'>Y - Cord</span>
                            <input disabled type='text' value={roundDecimals(destinationPoint.y)} onChange = {handleDestinationPointY} className='form-control' />
                          </div>
                      </span>
                      <button
                            // className={(newXCord && newYCord) ? 'editButton' : "editButtonDisabled"}
                            //disabled={(newXCord && newYCord) ? false : true}
                            onClick={(e) => handleCreateConnection(e, part.data.partTransformedCoordinates, index)}
                      >
                      Create Connection
                      </button>
                  </span>
                  )}

                  { ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "delete_hole")
                  &&(
                    <div style={{marginTop: "10px"}}>
                      <button
                        className={selectedPointType === "hole" ? 'editButton' : "editButtonDisabled"}
                        disabled={selectedPointType !== "hole"}
                        onClick={(e) => handleDeleteHole(e, index)}
                      >
                        Delete Hole
                      </button>
                    </div>
                  )}

                  { ((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                  && (editOption === "delete_line")
                  && (<span>Click on the line to to delete.</span>)
                  }
                  <div style={{marginTop: "10px"}}>
                    <button
                      className={undoData.length > 0 ? 'editButton' : "editButtonDisabled"}
                      disabled={undoData.length === 0}
                      onClick={handleUndo}
                    >
                      Undo
                    </button>
                    </div>
              </>
              }
            </div>  
        )}
      })
 
    }
  }

  const handelValChange = (e) => {
    setValue(e)

    if (e.e === 0 && e.f === 0) {
      centerAlignSvg(e)
    }
  }

  useEffect(() => {
    // Find the button by its selector
    const fitButton = document.querySelector('#root > div > div > div > div.outer > div.svgContainer > div.svgViewer > div > button:nth-child(5)');
    if (fitButton) {
      // Update the title attribute
      fitButton.setAttribute('title', 'Restore Viewer');
    }
  }, []);

  return (
    <div className="svgContainer"
      onContextMenu={(e) => {
        e.preventDefault();
        if (Object.keys(selectedlayer.data).length > 0) {
          setClicked(true);
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }
      }}
    >

      {renderMetaDataPropertyPane()}

      {clicked && (
        <div className='menuContextContainer' style={{ top: points.y + 'px', left: points.x + 'px' }}>
          <ul>
            <li onClick={(e) => props.createPart('part', null)}>Create Part</li>
            {/* <li onClick={(e) => props.deletePart('part', null)}>Delete Part</li> */}
            {
              parts.length > 0 && (
                <li onClick={(e) => props.createPart('hole', null)}>Create a Hole</li>
              )
            }
          </ul>
        </div>
      )}
      {/* (X:{mousePos.x}, Y:{mousePos.y}) */}
      {/* <button className="Edit Mode" onClick={(e) => centerAlignSvg(e)}>Fit (mode 2)</button> */}

      <ReactSVGPanZoom
        ref={Viewer}

        width={width} height={height}
        tool={tool} onChangeTool={setTool}
        value={value} onChangeValue={e => handelValChange(e)}
        miniatureProps={miniatureProps}
        onZoom={e => handelOriginPointRadius()}
        // onPan={e => console.log('pan')}
        onMouseMove={e => handleMouseMove(e)}
        background={"#000"}
        SVGBackground={"transparent"}
        className='svgViewer'
        preventPanOutside={false}


        detectAutoPan={false}
        scaleFactorMin={0.0001}
        // miniatureProps={{
        //   position: 'right'
        // }}
        toolbarProps={{
          position: 'right'
        }}
        style={{
          margin: 'auto'
        }}
        onClick={event => handleClick(event)}
        SVGStyle={{ width: '50%', marginRight: 0 }}
      >

        <svg id='mySvg' transform="scale(1, -1)"
          xmlns="http://www.w3.org/2000/svg"
          width={(props.preview===false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer') ?svgConfigWidthHeight.SVGWidth:svgWidthHeight.SVGWidth} height={(props.preview===false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')?svgConfigWidthHeight.SVGHeight:svgWidthHeight.SVGHeight}
        >
          <defs>
            <marker
              id="dot"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="5"
              markerHeight="5">
              <circle cx="5" cy="5" r="5" fill="#fff" />
            </marker>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="6"
              markerHeight="6"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" fill='#fff' />
            </marker>
          </defs>

          {(props.preview===true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer') ?
          <style>
            {`
          /* Define your CSS rules here */
          g.selected path{
            marker: url(#arrow);
          }
        `}
          </style>
          : ""}
          <g transform="scale(1, -1)">

            {props.paths}
            {renderParts()}
            
            {((props.preview === true && moduleName === 'assembly-viewer') || (props.preview === false && moduleName === 'config-viewer'))
                && getOriginForConfig()
            }  
          </g>
        </svg>
      </ReactSVGPanZoom>


    </div>
  );
};

export default SvgViewer;
