import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { requestJson } from "../../axios";
import { useDispatch } from "react-redux";
import Input from "../atom/input";
import ErrorMsg from "../errors/errorMsg";
import SvgComponent from "../../icons/svgComponent";
import { doArraysMatch } from "../utils/compareUtils";

import "./index.css";

function ScaleModal({ isModalOpen, setIsModalOpen, selectedItem, refetchApi }) {
  const initialValue = {
    scale: 0.2,
  };
  let upperLimit = 10.00;
  let lowerLimit = 0.01;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [startedGenerating, setStartedGenerating] = useState(false);
  const [error, setError] = useState("");
  const [allFields, setAllFields] = useState({ ...initialValue });
  const jsonIdTableData = useSelector((state) => state?.JsonIdTableData);

  function isSelectableDisabled() {
    const record = selectedItem?.selectedContainerRows;
    const requestJsonType = selectedItem?.requestJsonType;
    const allGeneratedAssemblies = jsonIdTableData?.data;

    if (requestJsonType == "DXF") {
      for (let index = 0; index < allGeneratedAssemblies.length; index++) {
        const element = allGeneratedAssemblies[index];

        if (
          JSON.parse(element.sourceID)[0] == record[0]?.sourceFileID &&
          element.scale == allFields["scale"] &&
          element?.status != "Failed"
        ) {
          return true; // Match found
        }
      }
      return false;
    } else if (requestJsonType == "STL") {
      for (let index = 0; index < allGeneratedAssemblies.length; index++) {
        const element = allGeneratedAssemblies[index];
        if (
          doArraysMatch(
            JSON.parse(element.sourceID),
            record.map((item) => item?.sourceFileID)
          ) &&
          element.scale == allFields["scale"] &&
          element?.status != "Failed"
        ) {
          return true; // Match found
        }
      }
      return false;
    } else {
      return false;
    }
  }

  const handleAdd = () => {
    // Validations

    // Check all mandatory files
    let mandatoryFieldMissing = false;
    for (let key in allFields) {
      if (allFields.hasOwnProperty(key) && !allFields[key]) {
        setError(`${key} is mandatory.`);
        mandatoryFieldMissing = true;
        return;
      }
    }

    if (mandatoryFieldMissing) {
      return;
    }

    // setLoading(true);
    // dispatch(addBusinessUnit(allFields))
    //   .then((result) => {
    //     refetchApi();
    //     setLoading(false);
    //     setAllFields({ ...initialValue });
    //     setIsModalOpen(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     setError(
    //       err?.response?.data?.responseDesc || `Error in assembly generate`
    //     );
    //   });

    handleRequestJson();
  };

  useEffect(() => {
    let flag = isSelectableDisabled();
    if (flag) {
      setError("Assembly already generated with this scale.");
    } else {
      setError("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    let flag = isSelectableDisabled();
    if (flag) {
      setError("Assembly already generated with this scale.");
    }
  }, [allFields["scale"]]);

  useEffect(() => {
    return () => {
      setError("");
      setAllFields({ ...initialValue });
      setLoading(false);
      setStartedGenerating(false);
    };
  }, []);

  const onChange = (e) => {
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target?.value,
    });

    if (e.target?.value >= lowerLimit && e.target?.value <= upperLimit) {
      setError("");
    } else {
      setError(`Scale should be in range from ${lowerLimit} to ${upperLimit}`);
    }
  };

  const handleRequestJson = () => {
    // setLoading(true);
    setStartedGenerating(true);
    let payload = {
      calcName: `CreateFrom${selectedItem?.requestJsonType}`,

      sourceContainerID: selectedItem?.sourceId,

      productType: selectedItem?.selectedContainerRows[0]
        ? selectedItem?.selectedContainerRows[0]?.conType
        : "",

      selectedFiles: selectedItem?.selectedContainerRows?.map(
        (val) => val?.sourceFileID
      ),
      scale: allFields?.scale,
    };

    dispatch(requestJson(payload))
      .then((result) => {
        // refetchApi();
        // setLoading(false);
        // setIsModalOpen(false);
        // setAllFields({ ...initialValue });
      })
      .catch((err) => {
        // Now will not wait for result
        // setLoading(false);
        // setError(
        //   err?.response?.data?.responseDesc || `Error in assembly generate`
        // );
      });
  };

  function infoComponent() {
    return (
      <div
        className="info-icon"
        data-toggle="tooltip"
        title={`Scale will have impact on the accuracy of conversion. Your scale should be in range of ${lowerLimit} to ${upperLimit} (inclusive of the range)`}
      >
        <SvgComponent
          type={"info"}
          width={"20px"}
          customClass={"headerIcon"}
          height={"20px"}
          background={"transprent"}
        />
      </div>
    );
  }

  function renderInfo() {
    return (
      <div>
        <h5>The Assembly file started Generating.</h5>
      </div>
    );
  }
  return (
    <Modal
      title={<h3>Resolution</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setAllFields({ ...initialValue });
        setError("");
        setLoading(false);
        setStartedGenerating(false);
      }}
      onOk={() => {
        if (startedGenerating) {
          refetchApi();
          setLoading(false);
          setIsModalOpen(false);
          setAllFields({ ...initialValue });
          setStartedGenerating(false);
        } else {
          handleAdd();
        }
      }}
      okText={startedGenerating ? "Ok" : "Continue"}
      destroyOnClose
      width={600}
      confirmLoading={loading}
      okButtonProps={{ disabled: error || !allFields?.scale }}
      cancelButtonProps={{
        style: {
          display: startedGenerating ? "none" : "initial",
        },
      }}
      // size={"large"}
    >
      {startedGenerating ? (
        renderInfo()
      ) : (
        <>
          {error && <ErrorMsg message={error} />}

          <Input
            id="scale"
            label="select a resolution for assembly generation"
            onChange={onChange}
            infoComponent={infoComponent}
            required={true}
            value={allFields["scale"]}
            type="number"
            min={lowerLimit}
            max={upperLimit}
          />
        </>
      )}
    </Modal>
  );
}

export default ScaleModal;
