const initialState = {
    loading: true,
    error: false,
    responseType: "",
    message: "",
    data: [] // Make sure data is initialized as an empty array
  }

const SvgUndoData = (state = initialState, action) => {
   // console.log("inside SvgUndoData: ",action)
    switch (action.type) {
      case "UNDO_LAST_ACTION_FOR_PART":
        console.log("State before update:", state);
        let newStateForUpdatePart = {
          ...state,
          data: [
            ...state.data,
            {
              actionName: action.payload.actionName,
              pointCoordinates: action.payload.data,
              partIndex: action.payload.partIndex,
              svgPath: action.payload.svgPath,
              pointType: action.payload.pointType,
              connections: action.payload.connections
            }
          ]
        };
        console.log("State after update:", newStateForUpdatePart);
        return newStateForUpdatePart;
      
      case "UNDO_LAST_ACTION_FOR_HOLE":
        console.log("State before update:", state);
        let newStateForUpdateHole = {
          ...state,
          data: [
            ...state.data,
            {
              actionName: action.payload.actionName,
              holeData: action.payload.data,
              partIndex: action.payload.partIndex,
              pointType: action.payload.pointType,
              connections: action.payload.connections
            }
          ]
        };
        console.log("State after update:", newStateForUpdateHole);
        return newStateForUpdateHole;
  
      case "DELETE_LAST_ACTION":
        let undoArray = [...state.data];  
        undoArray.pop()
        let newStateForUndo = {
          ...state,
          data: [...undoArray]
        };
        return newStateForUndo;

      case "DELETE_ALL_ACTIONS":
        console.log("Delete All Actions called")

        let emptyUndoArray = {
          ...state,
          data: []
        };
        return emptyUndoArray;

      default:
        return state;
    }
  };

  export default SvgUndoData;
  